// Основная палитра
const palette = {
    color0: '#FF0000',
    color1: '#1A2902',
    color2: '#344C11',
    color3: '#778D45',
    color4: '#AEC09A',
    color5: '#AEC670',
    color6: '#FFFFFF',
    color7: '#000000',
}


export default palette;