import palette from "./pallet";


// Набор тем для заголовков 1 уровень
const headers = {
    collection1: {
        color: `${palette.color6} !important`,
    },
    collection2: {
        color: `${palette.color7} !important`,
    }
}

// Набор тем для ссылок
const links = {
    collections1: {
        color: `${palette.color2} !important`,
    }
}

// Набор тем для полей вовода
const fields = {
    collection1: {
        color: `${palette.color2} !important`,
        backgroundColor: `${palette.color4} !important`,
        borderBottomColor: `${palette.color2} !important`,
        borderColor: `${palette.color2} !important`,
        fontColor: `${palette.color4} !important`,
    },
    collection2: {
        color: `${palette.color2} !important`,
        backgroundColor: `${palette.color6} !important`,
        borderBottomColor: `${palette.color2} !important`,
        borderColor: `${palette.color2} !important`,
        fontColor: `${palette.color4} !important`,
    }
};

// Набор тем для кнопок
const buttons = {
    collection1: {
        color: `${palette.color2} !important`,
        backgroundColor: `${palette.color2} !important`,
        borderColor: `${palette.color2} !important`,
    }
}

const dialogs = {
    collection1: {
        bgcolor: `${palette.color4} !important`,
        color: `${palette.color2} !important`,
    }
}

const drawers = {
    collection1: {
        backgroundColor: `${palette.color4} !important`,
        color: `${palette.color6} !important`,
    }
}

const cards = {
    collection1: {
        color: `${palette.color2} !important`,
    },
    collection2:{
        color: `${palette.color2} !important`,
        borderColor: `${palette.color3} !important`
    }
}


export {
    headers, links, fields, buttons, dialogs, drawers, cards
}