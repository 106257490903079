import React from 'react';

import { cards } from '../app/thema';
import { 
    urlGetTransferableEpicrisis, urlGetMilitaryDischarge, urlGetPatientCard, urlGetStationarySheet
} from '../common/helper/UrlRouter';

import { Grid, Avatar } from '@mui/material';

import { BtnCard, BtnCardImg } from '../components/Cards';


const model = {
    forma1: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: './img/doc.jpg',
        title: 'Переводной эпикриз',
        href: urlGetTransferableEpicrisis
    },
    forma2: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: './img/doc.jpg',
        title: 'Выписка в строй',
        href: urlGetMilitaryDischarge
    },
    forma3: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: './img/doc.jpg',
        title: 'Амбулаторная карта',
        href: urlGetPatientCard
    },
    forma4: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: './img/doc.jpg',
        title: 'Стационарный лист',
        href: urlGetStationarySheet
    }
}

/**
 * Виджет выбора типа форм регистрации пациента
 * @returns виджет
 */
export default function SelectorDocuments() {
    return (
        <Grid
            sx={{
                mt: '15px',
            }}
            container
        >
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={2} lg={4} xl={4}
            />
            <Grid
                item xs={12} sm={12} md={8} lg={4} xl={4}
            >
                <Grid
                    container spacing={2}
                >
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <BtnCardImg
                            thema={model.forma1.thema} fontSize={model.forma1.fontSize} img={model.forma1.img}
                            title={model.forma1.title} href={model.forma1.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <BtnCardImg
                            thema={model.forma2.thema} fontSize={model.forma2.fontSize} img={model.forma2.img}
                            title={model.forma2.title} href={model.forma2.href}
                        />                        
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <BtnCardImg
                            thema={model.forma3.thema} fontSize={model.forma3.fontSize} img={model.forma3.img}
                            title={model.forma3.title} href={model.forma3.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <BtnCardImg
                            thema={model.forma4.thema} fontSize={model.forma4.fontSize} img={model.forma4.img}
                            title={model.forma4.title} href={model.forma4.href}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={2} lg={4} xl={4}
            />
        </Grid>
    )
}