import React from 'react';

import { cards } from '../app/thema';
import { urlForma200, urlForma300, urlFormaDiseases, urlFormaTrauma } from '../common/helper/UrlRouter';

import { Grid, Avatar } from '@mui/material';

import { BtnCard, BtnCardImg } from '../components/Cards';


const model = {
    forma200: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: './img/200.jpg',
        title: 'Форма 200',
        href: urlForma200
    },
    forma300: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: './img/300.jpg',
        title: 'Форма 300',
        href: urlForma300
    },
    formaDiseases: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: './img/Diseases.jpg',
        title: 'Заболевания',
        href: urlFormaDiseases
    },
    formaTrauma: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: './img/Trauma.jpg',
        title: 'Травма',
        href: urlFormaTrauma
    }
}

/**
 * Виджет выбора типа форм регистрации пациента
 * @returns виджет
 */
export default function SelectorForms() {
    return (
        <Grid
            sx={{
                mt: '15px',
            }}
            container
        >
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={2} lg={4} xl={4}
            />
            <Grid
                item xs={12} sm={12} md={8} lg={4} xl={4}
            >
                <Grid
                    container spacing={2}
                >
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <BtnCardImg
                            thema={model.forma300.thema} fontSize={model.forma300.fontSize} img={model.forma300.img}
                            title={model.forma300.title} href={model.forma300.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <BtnCardImg
                            thema={model.forma200.thema} fontSize={model.forma200.fontSize} img={model.forma200.img}
                            title={model.forma200.title} href={model.forma200.href}
                        />                        
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <BtnCardImg
                            thema={model.formaDiseases.thema} fontSize={model.formaDiseases.fontSize} img={model.formaDiseases.img}
                            title={model.formaDiseases.title} href={model.formaDiseases.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <BtnCardImg
                            thema={model.formaTrauma.thema} fontSize={model.formaTrauma.fontSize} img={model.formaTrauma.img}
                            title={model.formaTrauma.title} href={model.formaTrauma.href}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={2} lg={4} xl={4}
            />
        </Grid>
    )
}
