import dayjs from 'dayjs';

/**
 * Вспомогательный метод для парсинга даты и времени из строки в формат даты
 * @param { Date } val строка в формате даты
 * @returns дата
 */
function ParsingByStringToDate(val) {
    let dt = new Date().toISOString();
    // Валидация
    if (val != null || val != undefined) {
        let tmp = new Date(val);
        dt = tmp.getFullYear() === 1
            ? dt
            : new Date(val).toISOString();
    }

    return dt;
}

/**
 * Вспомогательный метод для парсинга даты и времени из строки в формат даты
 * @param { Date } val строка в формате даты
 * @returns дата
 */
function ParsingByStringDayJsToDate(val) {
    let isValid = dayjs(val).isValid();
    // Валидация
    if (isValid) {
        let tmp = new Date(val);
        val = tmp.getFullYear() === 1 ? val : new Date(val).toISOString();
    }

    return val;
}

function ParsingByDateToString(val){
    var dt = '-';
    // Валидация
    if (val != null || val != undefined) {
        let tmp = new Date(val);
        dt = tmp.getFullYear() === 1
            ? dt
            : new Date(val).toString
    }

    return dt;
}

export {
    ParsingByStringToDate, ParsingByStringDayJsToDate, ParsingByDateToString,
}