import React, { memo, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Card,
    Grid,
    Typography,
} from "@mui/material";
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';

import PersonIcon from '@mui/icons-material/Person';

import {
    // selectCurrentPatientId,
} from "../recent/recentSlice";

import { fields, buttons, dialogs } from '../../app/thema';

// import { Search } from "./components/Search";
import { PatientsList } from "./components/PatientsList";


const RecentComponent = () => {
    const dispatch = useDispatch();

    // let currentPatientId = useSelector(selectCurrentPatientId);

    const [loading, setLoading] = useState(false);


    return <Grid
        className="no-print"
        sx={{
            mt: '15px',
        }}
        container
    >
        <Grid
            item
            // xs={1}
            md={0}
            lg={1}
        />
        <Grid
            item
            md={12}
            lg={10}
        >
            <form>
                <Card
                    sx={{
                        mx: '5px',
                        py: '15px',
                        px: '10px',
                        borderRadius: '10px'
                    }}
                    variant="outlined"
                >
                    <Grid
                        container spacing={2}
                    >
                        <Grid
                            style={{
                                textAlign: 'center',
                            }}
                            item
                            xs={12}
                        >
                            <Typography
                                variant="h4"
                                color={fields.collection2.borderColor}
                            >
                                Мои пациенты
                            </Typography>

                        </Grid>




                        <Box sx={{ width: '100%', pl: 3, pr: 3 }}>
                            <PatientsList/>
                        </Box>


                    </Grid>
                </Card>
            </form>
        </Grid>
        <Grid
            item
            md={0}
            lg={1}
        />

    </Grid>;
}



export const Recent = memo(RecentComponent);
