import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { styled, ThemeProvider, createTheme } from '@mui/material/styles';

import {
    Divider,
    Typography,
} from '@mui/material';



const PrintTpl_1Component = ({ printData }) => {


    console.log(printData);

    let { patientData, currentHistoryItem, currentEvent } = printData;


    patientData = {
        ...patientData,
        birthday: patientData.birthday ? new Date(patientData.birthday).toLocaleDateString() : "",
    };


    currentHistoryItem = {
        ...currentHistoryItem,
        hospitalizationDate: currentHistoryItem.hospitalizationDate ? new Date(currentHistoryItem.hospitalizationDate).toLocaleString() : "-",
        dischargeDate: currentHistoryItem.dischargeDate ? new Date(currentHistoryItem.dischargeDate).toLocaleString() : "-",
        evacuationLocation: currentHistoryItem.evacuationLocation === "в строй" || currentHistoryItem.evacuationLocation === "В строй" ? "строй" : currentHistoryItem.evacuationLocation,

        /**N EW */
        title1: currentHistoryItem.complaint || "-",
        title2: currentHistoryItem.complaint || "-",
        complaint: currentHistoryItem.complaint || "-",
        recommendations: currentHistoryItem.recommendations ? currentHistoryItem.recommendations.trim() : "",
        // complaint: currentHistoryItem.complaint || "-",
        // complaint: currentHistoryItem.complaint || "-",
    };

    let currentEventData = patientData.events[currentEvent] || {};
    let currentDate = new Date().toLocaleString();

    return <>

        <div className="page print">
            <style type="text/css" media="print">
                {" @page { size: portrait; } "}
            </style>
            <table className="table">
                <tbody>
                    <tr>
                        <td className="bright">




                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="bheader">Медицинская рота войсковой части 01069 (н. п. Красный Октябрь)</td>
                                    </tr>
                                    <tr>
                                        <td className="bheader">Выписной эпикриз №{currentEventData.idXls} от {currentDate}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">ФИО:</div>
                                            <div className="bcontent">{patientData.fio}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Дата рождения:</div>
                                            <div className="bcontent">{patientData.birthday}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Воинское звание:</div>
                                            <div className="bcontent">{patientData.militaryRank}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Личный номер:</div>
                                            <div className="bcontent">{patientData.suid}</div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Воинская часть, подразделение:</div>
                                            <div className="bcontent">{patientData.militaryUnit}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Находился на лечении:</div>
                                            <div className="bcontent">{currentHistoryItem.location} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; с {currentHistoryItem.hospitalizationDate} по {currentHistoryItem.dischargeDate}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <b>Жалобы:</b>
                                            <div className="text-block">
                                                {patientData.complaint || currentHistoryItem.complaint || currentEventData.complaint || ""}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <b>Анамнез:</b>
                                            <div className="text-block">
                                                Дата и время ранения: {currentEventData.eventDate ? new Date(currentEventData.eventDate).toLocaleString() : "-"}<br />
                                                Место ранения: {currentEventData.location || "-"}<br />
                                                Обстоятельства ранения/болезни: {currentEventData.circumstances || "-"}<br />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <b>Диагноз:</b>
                                            <div className="text-block">
                                                {currentHistoryItem.disease}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <b>Лечение:</b>
                                            <div className="text-block">
                                                {currentHistoryItem.helping}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <b>Рекомендации при выписке:</b>
                                            <div className="text-block">
                                                {currentHistoryItem.recommendations || ""}
                                            </div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle bold">Направляется в</div>
                                            <div className="bcontent bold">{currentHistoryItem.evacuationLocation}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex noborder">

                                            <table className="w100">
                                                <tbody>
                                                    <tr>
                                                        <td className="w40">Начальник медицинской службы<br /> Медроты 41 МСП, Красный октябрь</td>
                                                        <td className="w40 borderBottom"></td>
                                                        <td className="w20">
                                                            ст. л-т м/с
                                                            <br />
                                                            Иванов Э.А.
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex noborder">

                                            <table className="w100">
                                                <tbody>
                                                    <tr>
                                                        <td className="w40">Лечащий врач<br /> Медроты 41 МСП, Красный октябрь</td>
                                                        <td className="w40 borderBottom"></td>
                                                        <td className="w20">
                                                            л-т м/с
                                                            <br />
                                                            {currentHistoryItem.doctor}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>




                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>;
}

export const PrintTpl_1 = memo(PrintTpl_1Component);
