// REST API Контроллер для взаимодействия с документами
import {
    ResponseCheck, GetFetchAsync, PostFetchAsync, PutFetchAsync, DeleteFetchAsync,
} from '../api/QueryHandler';
import {
    GetTransferableEpicrisisPOST, GetMilitaryDischargePOST, GetPatientCardPOST, GetStationarySheetPOST,
} from '../helper/UrlController';
import {
    ResponseAlert, ItemEventTransferableEpicrisis, ItemTransferableEpicrisis,
    ItemEventMilitaryDischarge, ItemEventPatientCard, ItemEventStationarySheet
} from '../models/Response';
import {
    RequestDocGet,
} from '../models/Request';


/**
 * API для формирования документа переводного эпикриза
 * @param { String } idPersonnel УИН записи журнала личного состава
 */
async function GetTransferableEpicrisisAsync(idPersonnel) {
    let model = {
        alert: {},
        result: [],
    };
    try {
        let req = new RequestDocGet(idPersonnel).getModel();
        let res = await PostFetchAsync(GetTransferableEpicrisisPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = new ItemEventTransferableEpicrisis(
            res.result.idEvent, res.result.dateOfInjury, res.result.locationWhereInjury, res.result.circumstances, 
            res.result.transferableEpicrisis
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для формирования документа выписки в строй
 * @param { String } idPersonnel УИН записи журнала личного состава
 */
async function GetMilitaryDischargeAsync(idPersonnel) {
    let model = {
        alert: {},
        result: {}
    };
    try {
        let req = new RequestDocGet(idPersonnel).getModel();
        let res = await PostFetchAsync(GetMilitaryDischargePOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = new ItemEventMilitaryDischarge(
            res.result.idEvent, res.result.dateOfInjury, res.result.locationWhereInjury, res.result.circumstances
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для формирования документа амбулаторная карта
 * @param { String } idPersonnel УИН записи журнала личного состава
 */
async function GetPatientCard(idPersonnel) {
    let model = {
        alert: {},
        result: {}
    };
    try {
        let req = new RequestDocGet(idPersonnel).getModel();
        let res = await PostFetchAsync(GetPatientCardPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = new ItemEventPatientCard(
            res.result.idEvent, res.result.dateOfInjury, res.result.locationWhereInjury, res.result.circumstances,
            res.result.nature
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для формирования документа стационарный лист
 * @param { String } idPersonnel УИН записи журнала личного состава
 */
async function GetStationarySheet(idPersonnel) {
    let model = {
        alert: {},
        result: {}
    };
    try {
        let req = new RequestDocGet(idPersonnel).getModel();
        let res = await PostFetchAsync(GetStationarySheetPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = new ItemEventStationarySheet(
            res.result.idEvent, res.result.dateOfInjury, res.result.locationWhereInjury, res.result.circumstances,
            res.result.nature
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}


export {
    GetTransferableEpicrisisAsync, GetMilitaryDischargeAsync, GetPatientCard, GetStationarySheet,
}