import React from 'react';
import { useNavigate } from "react-router-dom";

import {
    Grid, Card, CardHeader, Avatar, CardActions, Button, CardActionArea, CardContent, Typography
} from '@mui/material';

import { ParsingByDateToString } from '../common/helper/Parser';


/**
 * Компонент кнопка в 
 * @param { object } props передаваемые параметры в компонент
 * @returns 
 */
function BtnCard(props) {
    const navigate = useNavigate();
    return (
        <Card>
            <CardActionArea
                disabled={props.isDisabled}
                onClick={() => { navigate(props.href) }}>
                <CardHeader
                    sx={{
                        '& .MuiCardHeader-title': {
                            fontSize: props.fontSize,
                            color: props.thema.color,
                        },
                    }}
                    avatar={
                        <Avatar aria-label="recipe">
                            {props.avatar}
                        </Avatar>
                    }
                    title={props.title}
                />
            </CardActionArea>
        </Card>
    )
}

/**
 * Компонент кнопка в 
 * @param { object } props передаваемые параметры в компонент
 * @returns 
 */
function BtnCardImg(props) {
    const navigate = useNavigate();
    return (
        <Card>
            <CardActionArea
                disabled={props.isDisabled}
                onClick={() => { navigate(props.href) }}>
                <CardHeader
                    sx={{
                        '& .MuiCardHeader-title': {
                            fontSize: props.fontSize,
                            color: props.thema.color,
                        },
                    }}
                    avatar={
                        <Avatar
                            sx={{
                                width: 48,
                                height: 48
                            }}
                            src={props.img}
                        />
                    }
                    title={props.title}
                />
            </CardActionArea>
        </Card>
    )
}


function TransferableEpicrisisCard(props) {
    const navigate = useNavigate();

    return (
        <Card
            sx={{
                border: '1px solid',
                borderColor: props.thema.borderColor,
            }}
        >
            <CardContent>
                <Grid
                    container spacing={2}
                >
                    <Grid
                        item xs={12} sm={12} md={6} lg={4} xl={4}
                    >
                        <Typography
                            variant={props.locationMedicalAssistance.variant} color={props.locationMedicalAssistance.thema.borderColor}
                        >
                            {props.locationMedicalAssistance.label} {props.locationMedicalAssistanceValue}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={3} lg={4} xl={4}
                    >
                        <Typography
                            variant={props.dateOfVisits.variant} color={props.dateOfVisits.thema.borderColor}
                        >
                            {props.dateOfVisits.label} {props.dateOfVisitsValue}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={3} lg={4} xl={4}
                    >
                        <Typography
                            variant={props.dateOfEvacuation.variant} color={props.dateOfEvacuation.thema.borderColor}
                        >
                            {props.dateOfEvacuation.label} {props.dateOfEvacuationValue}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <Typography
                            variant={props.helpProvided.variant} color={props.helpProvided.thema.borderColor}
                        >
                            {props.helpProvided.label} {props.helpProvidedValue}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <Typography
                            variant={props.diagnosis.variant} color={props.diagnosis.thema.borderColor}
                        >
                            {props.diagnosis.label} {props.diagnosisValue}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <Typography
                            variant={props.recommendations.variant} color={props.recommendations.thema.borderColor}
                        >
                            {props.recommendations.label} {props.recommendationsValue}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button size="small">Распечатать</Button>
            </CardActions>
        </Card>
    )
}

export {
    BtnCard, BtnCardImg, TransferableEpicrisisCard
}