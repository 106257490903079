import React from 'react';
import {
    Drawer, Box, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse
} from '@mui/material';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


/**
 * Компонент боковое меню с лева
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function MenuLeftDwr(props) {
    return (
        <Drawer
            open={props.open} onClose={props.handleClose(false)}
        >
            <Box
                sx={{
                    width: 200
                }}
                role="presentation"
            >
                {props.elems.map((item) => (
                    item
                ))}
            </Box>
        </Drawer>
    )
}

/**
 * Компонент список элементов меню
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function ListDwr(props) {
    return (
        <List>
            {props.elems.map((item) => (
                item
            ))}
        </List>
    )
}

/**
 * Компонент полоса разделитель для меню
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function DividerDwr(props) {
    return (
        <Divider key={props.guid} />
    )
}

/**
 * Компонент элемент списка для меню
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function ItemListDwr(props) {
    return (
        <ListItem
            sx={{
                "&:hover": {
                    backgroundColor: props.thema.backgroundColor,
                    color: props.thema.color,
                    "& .MuiListItemIcon-root": {
                        color: props.thema.color
                    }
                }
            }}
            key={props.guid} disablePadding
        >
            <ListItemButton
                onClick={props.handleClickItem} disabled={props.disabled}
            >
                <ListItemIcon>
                    {props.icon}
                </ListItemIcon>
                <ListItemText
                    primary={props.text}
                />
            </ListItemButton>
        </ListItem>
    )
}

/**
 * Компонент элемент список с вложенным списком для меню
 * @param { Object } props передаваемые параметры в компонент
 */
function CollapseListDwr(props) {
    return (
        <ListItem
            sx={{
                "&:hover": {
                    backgroundColor: props.thema.backgroundColor,
                    color: props.thema.color,
                    "& .MuiListItemIcon-root": {
                        color: props.thema.color
                    }
                }
            }}
            key={props.guid} disablePadding
        >
            <ListItemButton
                onClick={props.handleClickItem} disabled={props.disabled}
            >
                <ListItemIcon>
                    {props.icon}
                </ListItemIcon>
                <ListItemText
                    primary={props.text}
                />
                {props.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            <Collapse 
                in={props.open} timeout="auto" unmountOnExit
            >
                <List 
                    component="div" disablePadding
                >
                    {props.collapseItems}
                </List>
            </Collapse>
        </ListItem>
    )
}

function ItemCollapseListDwr(props) {
    return (
        <ListItemButton
            sx={{
                pl: 4
            }}
            onClick={props.handleClick} disabled={props.disabled}
        >
            <ListItemIcon>
                {props.icon}
            </ListItemIcon>
            <ListItemText
                primary={props.text}
            />
        </ListItemButton>
    )
}


export {
    DividerDwr, ItemListDwr, CollapseListDwr, ItemCollapseListDwr
}