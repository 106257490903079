import React from 'react';
import { CircularProgress } from '@mui/material';


/**
 * 
 * @param {*} props 
 * @returns 
 */
function CircularLeftPrg(props) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'left',
            }}
        >
            <CircularProgress
                color="success"
            />
            </div>
    )
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
function CircularRightPrg(props) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'right',
            }}
        >
            <CircularProgress
                color="success"
            />
            </div>
    )
}


export {
    CircularLeftPrg, CircularRightPrg,
}