import React from 'react';
import { fields } from '../app/thema';
import { Grid } from '@mui/material';
import { FindFld } from '../components/Fields';


/**
 * Виджет поле поиска по позывному
 * @param { Object } props передаваемые параметры
 * @returns виджет
 */
function CallSign(props) {
    const model = {
        callSign: {
            thema: fields.collection2,
            size: 'small',
            variant: 'outlined',
            name: 'callSign',
            label: 'Позывной',
        },
    }

    return (
        <Grid
            item xs={12} sm={12} md={12} lg={12} xl={12}
        >
            <FindFld
                thema={model.callSign.thema} name={model.callSign.name} size={model.callSign.size}
                variant={model.callSign.variant} label={model.callSign.label} value={props.formik.values.callSign}
                show={props.callSignOk}
                handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange} handleKeyUp={props.handleKeyUp}
                handleClick={props.handleClick}
                touched={props.formik.touched.callSign} error={props.formik.errors.callSign}
            />
        </Grid>
    )
}


export {
    CallSign
}