import { API_URLS } from "../../constants";
import { POST } from "../../helpers";


let authentication = sessionStorage.getItem('authentication') ? JSON.parse(sessionStorage.getItem('authentication')) : {};
let authParams = {
    authkey: authentication.authkey || "",
    key: authentication.key || "",
}

export function fetchSendSearch({ data = {}, success = null }) {
  let authentication = JSON.parse(sessionStorage.getItem('authentication'));

  return POST({
    path: API_URLS.main.CommonSearch,
    data: {
      key: authentication.key,
      authkey: authentication.authkey,
      value: data.text,
      event: 300,
      ...data,
    },
    success: (result) => {
      // let searchResult = result && result.concepts && result.concepts.length > 0 ? result.concepts : [];
      let searchResult = result && result.items && result.items.length > 0 ? result.items : []; // MOCKUP
      console.log(searchResult);
      success && success(searchResult);
    },
  });
}



export function fetchSendGetPatientData({ data = {}, success = null }) {

  return POST({
    path: API_URLS.main.GetPatientData,
    data: {
      ...data,
      id: data.id,
      ...authParams,
    },
    success: () => {
      success && success();
    },
  });
}




export function fetchGetSymptomsFromText({ data = {} }) {
  return POST({
    path: API_URLS.main.GetSymptomsFromText,
    data: {
      authkey: data.authKey,
      text: data.text,
      type: data.type,
    },
  });
}

export function fetchSendUpdate300({ data = {}, success = null }) {

  return POST({
    path: API_URLS.main.UpdateForm300,
    data: {
      ...data,
      ...authParams,
    },
    success: () => {
      success && success();
    },
  });
}
