import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import {
    Autocomplete,
    Divider,
    Box,
    Typography,
    Grid,
    Paper,
    Stack,
    TextField,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
    selectPatientData,
    sendGetHistoryPatientsByUser,
    selectHistoryPatients,
} from "../../recentSlice";

import {
    setCurrentHistoryItem,
} from "../../../history/historySlice";

import {  urlHistory } from '../../../../common/helper/UrlRouter';

// import { OtherDiseases } from "../OtherDiseases";
// import { HistoryItem } from "../HistoryItem";


const TableCellHeadStyled = styled(TableCell)(({ theme }) => ({
    fontWeight: 700,
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#444',
    color: theme.palette.background.paper,
    letterSpacing: "0.05071em"
}));


const PatientsListComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const historyPatients = useSelector(selectHistoryPatients);



    useEffect(() => {
        dispatch(sendGetHistoryPatientsByUser());
    }, []);





    const hanldleClickRow = (rowId) => {
        console.log(rowId);
        navigate(`${urlHistory}/${rowId}`);

    };


    console.log(historyPatients);
    return <>
        <Grid
            container
            sx={{
                m: 2,
                mt: 4,
            }}
        >
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCellHeadStyled>Номер жетона</TableCellHeadStyled>
                            <TableCellHeadStyled>ФИО</TableCellHeadStyled>
                            <TableCellHeadStyled>Диагноз</TableCellHeadStyled>
                            <TableCellHeadStyled>Степень тяжести</TableCellHeadStyled>
                            <TableCellHeadStyled>Статус</TableCellHeadStyled>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {historyPatients.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{
                                    'td': { border: "1px solid gray !important" },
                                    cursor: "pointer"
                                }}
                                onClick={() => hanldleClickRow(row.id)}
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.suid}</TableCell>
                                <TableCell>{row.fio}</TableCell>
                                <TableCell>{row.diagnosis}</TableCell>
                                <TableCell>{row.gravityInjury}</TableCell>
                                <TableCell>{row.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        </Grid>



    </>;
}

export const PatientsList = memo(PatientsListComponent);
