// REST API Контроллер взаимодействия с биллингом
import {
    ResponseCheck, GetFetchAsync, PostFetchAsync, PutFetchAsync, DeleteFetchAsync,
    IsCode200
} from './QueryHandler';
import {
    UserCheckPOST,
    CheckKeyPOST,
} from '../helper/UrlController';
import {
    ResponseAlert, ResponseUserCheck,
} from '../models/Response';
import {
    RequestUserCheck,
} from '../models/Request';


/**
 * API для аутентификации пользователя
 * @param { string } login пользователь
 * @param { string } password пароль
 */
async function Authorization(login, password, key) {
    let model = {
        alert: {},
        result: {},
    };
    try {
        let req = new RequestUserCheck(
            login, password)
            .getModel();
        let res = await PostFetchAsync(UserCheckPOST().url, req);

        res.result = {
            ...res,

            "idPersonnel": res.userId,
            "idMilitaryMedicalOrganization": res.organizationId,
            "login": login,
            "password": password,
            "role": res.userRole ? res.userRole : "",
            "militaryRankShort": "",
            "key": key,


        };

        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky)
            .getModel();
        model.result = new ResponseUserCheck(
            res.result.idPersonnel, res.result.login, res.result.password, res.result.role, res.result.key, res.result.authkey)
            .getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для проверки ключа
 * @param { string } login пользователь
 * @param { string } password пароль
 */
async function CheckKey( key, authkey) {
    let model = {
        alert: {},
        result: {},
    };
    try {
        let req = {
            active_language: "ru",
            authkey: authkey,
            conceptId: null,
            event: 300,
            key: key,
            text: "111",
            type: "TokenFIO",
            value: "111",
        }
        let res = await PostFetchAsync(CheckKeyPOST().url, req);

        // Валидация
        if (!ResponseCheck(res)) {
            // throw new Error("sdfsdf");
            
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky)
            .getModel();
        // model.result = new ResponseUserCheck(
        //     res.result.idPersonnel, res.result.login, res.result.password, res.result.role, res.result.key, res.result.authkey)
        //     .getModel();
    } catch (err) {
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}


export {
    Authorization,
    CheckKey,
}
