import React from 'react';

import {
    Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemAvatar, Avatar, ListItemText
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';


/**
 * Компонент диалоговое окно выбора личного состава
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function PersonnalDlg(props) {
    const { thema, elems, dialogTitle, selectedValue, open, onClose, } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog
            onClose={handleClose} open={open}
        >
            <DialogTitle>
                {props.type ? `${dialogTitle.title1}` : `${dialogTitle.title2}`}
            </DialogTitle>
            <List
                sx={{
                    pt: 0
                }}
            >
                {elems.map((item) => (
                    <ListItem disableGutters key={item.guid}>
                        <ListItemButton
                            onClick={() => handleListItemClick(item)}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: thema.bgcolor,
                                        color: thema.color
                                    }}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${item.surname} ${item.name} ${item.patronymic}`}
                                secondary={props.type ? `${item.tokenNumber}` : `${item.callSign}`}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog >
    )
}

/**
 * Компонент диалоговое окно выбора с возможностью добавления новой записи личного состава 
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function PersonnalByInsertDlg(props) {
    const { thema, elems, dialogTitle, selectedValue, open, onClose, } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const handleListItemNewClick = () => {
        onClose(null);
    }

    return (
        <Dialog
            onClose={handleClose} open={open}
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <List
                sx={{
                    pt: 0
                }}
            >
                <ListItem disableGutters>
                    <ListItemButton
                        autoFocus
                        onClick={() => handleListItemNewClick()}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Добавить новую запись" />
                    </ListItemButton>
                </ListItem>
                {elems.map((item) => (
                    <ListItem disableGutters key={item.guid}>
                        <ListItemButton
                            onClick={() => handleListItemClick(item)}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: thema.bgcolor,
                                        color: thema.color
                                    }}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${item.surname} ${item.name} ${item.patronymic}`}
                                secondary={`${item.tokenNumber}`}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog >
    )
}

/**
 * Компонент диалоговое окно выбора истории болезни
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function HistoryDlg(props) {
    const { thema, elems, dialogTitle, selectedValue, open, onClose, } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };
    return (
        <Dialog
            onClose={handleClose} open={open}
        >
            <DialogTitle>
                {dialogTitle.title1}
            </DialogTitle>
            <List
                sx={{
                    pt: 0
                }}
            >
                {elems.map((item) => (
                    <ListItem disableGutters key={item.guid}>
                        <ListItemButton
                            onClick={() => handleListItemClick(item)}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: thema.bgcolor,
                                        color: thema.color
                                    }}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`Время поступления:${item.dateOfVisits} Время эвакуации/выписки:${item.dateOfEvacuation}`}
                                secondary={`Диагноз: ${item.diagnosis}`}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog >
    )
}


export {
    PersonnalDlg, PersonnalByInsertDlg, HistoryDlg,
}