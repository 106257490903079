import React from 'react';


/**
 * Компонент картинка приветствия
 * @param { object} props передаваемые параметры в компонент
 * @returns компонент
 */
function WelcomeImg(props){
    return (
        <img src={props.src} width={props.width} alt={props.alt} />
    )
}


export{
    WelcomeImg,
}