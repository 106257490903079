import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";

import { fields, buttons, dialogs } from '../../app/thema';

import { alertError, alertWarn, alertInfo } from '../../common/helper/ToastAlert';
import { urlDocuments } from '../../common/helper/UrlRouter';
import { IsCode200, IsCode503 } from '../../common/api/QueryHandler';
import { FindByStringAsync } from '../../common/api/Personnel';
import { MilitaryHandbooksAsync, Handbook300Async } from '../../common/api/Handbook';
import { GetTransferableEpicrisisAsync } from '../../common/api/Documents';
import { ParsingByStringToDate, ParsingByStringDayJsToDate } from '../../common/helper/Parser';

import { Grid, Card, Typography } from '@mui/material';

import InfoByPersonnel from '../../widgets/InfoByPersonnel';
import InfoByMilitaryTicketFull from '../../widgets/InfoByMilitaryTicketFull';
import FindByParser from '../../widgets/FindByParser';
import InfoByDoc from '../../widgets/InfoByDoc';

import { EndIconBtn, StartIconBtn } from '../../components/Buttons';
import { CircularRightPrg } from '../../components/Progress';
import { PersonnalDlg, HistoryDlg } from '../../components/Dialogs';

import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';


/**
 * 
 * @returns 
 */
export default function PTransferableEpicrisis() {
    const navigate = useNavigate();

    const [step, setStep] = useState(0);

    var personnels = [];
    var selectedPersonnal = {};
    const [openPersonnalDlg, setOpenPersonnalDlg] = useState(false);
    const [personnalDlgType, setPersonnalDlgType] = useState(true);

    var historys = [];
    var selectedHistory = {};
    const [openHistoryDlg, setOpenHistoryDlg] = useState(false);
    const [historyDlgType, setHistoryDlgType] = useState(true);

    const [loading, setLoading] = useState(false);
    const [findFldOk, setFindFldOk] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isView, setView] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [militaryPartJson, setMilitaryPartJson] = useState([]);
    var militaryPartList = [];
    const [militaryUnitJson, setMilitaryUnitJson] = useState([]);
    var militaryUnitList = [];
    const [militaryCompanyJson, setMilitaryCompanyJson] = useState([]);
    var militaryCompanyList = [];
    const [militaryPlatoonJson, setMilitaryPlatoonJson] = useState([]);
    var militaryPlatoonList = [];
    const [militaryDepartmentJson, setMilitaryDepartmentJson] = useState([]);
    var militaryDepartmentList = [];
    const [militaryRankJson, setMilitaryRankJson] = useState([]);
    var militaryRankList = [];

    const [locationMedicalAssistanceJson, setLocationMedicalAssistanceJson] = useState([]);
    var locationMedicalAssistenceList = [];
    const [locationWhereInjuryJson, setLocationWhereInjuryJson] = useState([]);
    var locationWhereInjuryList = [];
    const [institutionIsLocationJson, setInstitutionIsLocationJson] = useState([]);
    var institutionIsLocationList = [];
    const [locationWhereJson, setLocationWhere] = useState([]);
    var locationWhereList = [];

    const [militaryPart, setMilitaryPart] = useState({ id: '', label: '' });
    const [militaryUnit, setMilitaryUnit] = useState({ id: '', label: '' });
    const [militaryCompany, setMilitaryCompany] = useState({ id: '', label: '' });
    const [militaryPlatoon, setMilitaryPlatoon] = useState({ id: '', label: '' });
    const [militaryDepartment, setMilitaryDepartment] = useState({ id: '', label: '' });
    const [militaryRank, setMilitaryRank] = useState({ id: '', label: '' });
    const [locationMedicalAssistance, setLocationMedicalAssistance] = useState({ id: '', label: '' });
    const [institutionIsLocation, setInstitutionIsLocation] = useState({ id: '', label: '' });
    const [locationWhereInjury, setLocationWhereInjury] = useState({ id: '', label: '' });

    const [isEditMilitaryPath, setIsEditMilitaryPath] = useState(false);
    const [isEditMilitaryUnit, setIsEditMilitaryUnit] = useState(false);
    const [isEditMilitaryCompany, setIsEditMilitaryCompany] = useState(false);
    const [isEditMilitaryPlatoon, setIsEditMilitaryPlatoon] = useState(false);
    const [isEditMilitaryDepartment, setIsEditMilitaryDepartment] = useState(false);
    const [isEditMilitaryRank, setIsEditMilitaryRank] = useState(false);
    const [isEditLocationMedicalAssistance, setIsEditLocationMedicalAssistance] = useState(false);
    const [isEditLocationWhereInjury, setIsEditLocationWhereInjury] = useState(false);
    const [isEditInstitutionIsLocation, setIsEditInstitutionIsLocation] = useState(false);
    const [isEditLocationWhere, setEditLocationWhere] = useState(false);

    const [isDisabledSurname, setIsDisabledSurname] = useState(false);
    const [isDisabledName, setIsDisabledName] = useState(false);
    const [isDisabledPatronymic, setIsDisabledPatronymic] = useState(false);
    const [isDisabledBirthday, setIsDisabledBirthday] = useState(false);
    const [isDisabledPhoneNumber, setIsDisabledPhoneNumber] = useState(false);
    const [isDisabledMilitaryUnit, setIsDisabledMilitaryUnit] = useState(false);
    const [isDisabledMilitaryPath, setIsDisabledMilitaryPath] = useState(false);
    const [isDisabledMilitaryCompany, setIsDisabledMilitaryCompany] = useState(false);
    const [isDisabledMilitaryPlatoon, setIsDisabledMilitaryPlatoon] = useState(false);
    const [isDisabledMilitaryDepartment, setIsDisabledMilitaryDepartment] = useState(false);
    const [isDisabledMilitaryRank, setIsDisabledMilitaryRank] = useState(false);

    const [isDisabledDateAction, setIsDisabledDateAction] = useState(false);
    const [isDisabledDateOfInjury, setIsDisabledDateOfInjury] = useState(false);
    const [isDisabledDateOfEvacuation, setIsDisabledDateOfEvacuation] = useState(false);
    const [isDisabledLocationMedicalAssistance, setIsDisabledLocationMedicalAssistance] = useState(false);
    const [isDisabledLocationWhereInjury, setIsDisabledLocationWhereInjury] = useState(false);
    const [isDisabledInstitutionIsLocation, setIsDisabledInstitutionIsLocation] = useState(false);
    const [isDisabledCircumstances, setIsDisabledCircumstances] = useState(false);
    const [isDisabledComplaint, setIsDisabledComplaint] = useState(false);
    const [isDisabledAssistanceProvided, setIsDisabledAssistanceProvided] = useState(false);
    const [isDisabledDiagnosis, setIsDisabledDiagnosis] = useState(false);
    const [isDisabledRecommendations, setIsDisabledRecommendations] = useState(false);


    const handleSetLocationMedicalAssistanceList = (val) => {
        locationMedicalAssistenceList = val;
        setLocationMedicalAssistanceJson(val);
        if (formik.values.locationMedicalAssistance.label === '') {
            setLocationMedicalAssistance(val[0]);
        }
    };
    const handleClickLocationMedicalAssistance = () => {
        setIsEditLocationMedicalAssistance(!isEditLocationMedicalAssistance);
    };

    const handleSetLocationWhereInjury = (val) => {
        locationWhereInjuryList = val;
        setLocationWhereInjuryJson(val);
        if (formik.values.locationWhereInjury.label === '') {
            setLocationWhereInjury(val[0]);
        };
    };
    const handleClickLocationWhereInjury = () => {
        setIsEditLocationWhereInjury(!isEditLocationWhereInjury);
    };

    const handleSetInstitutionIsLocationList = (val) => {
        institutionIsLocationList = val;
        setInstitutionIsLocationJson(val);
        if (formik.values.institutionIsLocation.label === '') {
            setInstitutionIsLocation(val[0]);
        };
    };
    const handleClickInstitutionIsLocation = () => {
        setIsEditInstitutionIsLocation(!isEditInstitutionIsLocation);
    };

    const handleSetMilitaryPartList = (val) => {
        militaryPartList = val;
        setMilitaryPartJson(val);
        if (formik.values.militaryUnit === '') {
            setMilitaryPart(val[0]);
        }
    };
    const handleClickMilitaryPath = () => {
        setIsEditMilitaryPath(!isEditMilitaryPath);
    };

    const handleSetMilitaryUnitList = (val) => {
        militaryUnitList = val;
        setMilitaryUnitJson(val);
        if (formik.values.division === '') {
            setMilitaryUnit(val[0]);
        }
    };
    const handleClickMilitaryUnit = () => {
        setIsEditMilitaryUnit(!isEditMilitaryUnit);
    };

    const handleSetCompanyList = (val) => {
        militaryCompanyList = val;
        setMilitaryCompanyJson(val);
        if (formik.values.rota === '') {
            setMilitaryCompany(val[0]);
        }
    };
    const handleClickMilitaryCompany = () => {
        setIsEditMilitaryCompany(!isEditMilitaryCompany);
    };

    const handleSetMilitaryPlatoonList = (val) => {
        militaryPlatoonList = val;
        setMilitaryPlatoonJson(val);
        if (formik.values.platoon === '') {
            setMilitaryPlatoon(val[0]);
        }
    };
    const handleClickMilitaryPlatoon = () => {
        setIsEditMilitaryPlatoon(!isEditMilitaryPlatoon);
    };

    const handleSetMilitaryDepartmentList = (val) => {
        militaryDepartmentList = val;
        setMilitaryDepartmentJson(val);
        if (formik.values.department === '') {
            setMilitaryDepartment(val[0]);
        }
    };
    const handleClickMilitaryDepartment = () => {
        setIsEditMilitaryDepartment(!isEditMilitaryDepartment);
    };

    const handleSetMilitaryRankList = (val) => {
        militaryRankList = val;
        setMilitaryRankJson(val);
        if (formik.values.actualRank === '') {
            setMilitaryRank(val[0]);
        }
    };
    const handleClickMilitaryRank = () => {
        setIsEditMilitaryRank(!isEditMilitaryRank);
    };

    const handleBackClick = () => {
        navigate(urlDocuments);
    };

    // Загружаем информацию по пациенту
    const handleDataLoader = async () => {
        let handbook = await MilitaryHandbooksAsync();
        let handbook300 = await Handbook300Async();

        handleSetMilitaryPartList(handbook.result.militaryPart);
        handleSetMilitaryUnitList(handbook.result.militaryUnit);
        handleSetCompanyList(handbook.result.militaryCompany);
        handleSetMilitaryPlatoonList(handbook.result.militaryPlatoon);
        handleSetMilitaryDepartmentList(handbook.result.militaryDepartment);
        handleSetMilitaryRankList(handbook.result.militaryRank);

        handleSetLocationMedicalAssistanceList(handbook300.result.locationWhere);
        handleSetLocationWhereInjury(handbook300.result.locationWhereInjury);
        handleSetInstitutionIsLocationList(handbook300.result.locationWhere);
    };

    const handleFindFldKeyUp = async (e) => {
        if (e.keyCode !== 13) return;

        handleNextClick();
    };
    const handleFindFldClick = async () => {
        //setFindFldOk(false);
        //setStep(0);
    };
    const handleFindFld = async () => {
        let res = await FindByStringAsync(formik.values.findFld);
        // Валидация запроса
        if (!IsCode200(res)) {
            setStep(0);
            setView(true);
            alertInfo('Пациента с такими данными не найден !');

            return;
        }

        personnels = res.result;
        if (res.result.length === 1) {
            let per = res.result[0];
            setStep(1);
            selectedPersonnal = per;
            handleFindHistory(per.id);

            return;
        }

        setFindFldOk(true);
        setStep(1);
        setPersonnalDlgType(false);
        setOpenPersonnalDlg(true);
    };

    const handleFindHistory = async (id) => {
        const res = await GetTransferableEpicrisisAsync(id);
        // Валидация запроса
        if (!IsCode200(res)) {
            return;
        }

        if (res.result.transferableEpicrisis === null ||
            res.result.transferableEpicrisis === undefined) {
            setView(true);
            alertInfo('У пациента нет истории болезни!');

            return;
        }

        historys = res.result;
        if (res.result.transferableEpicrisis.length === 1) {
            setView(false);
            setStep(2);
            selectedHistory = res.result;

            return;
        }

        setFindFldOk(true);
        setView(false);
        setStep(2);
        //setHistorysDlgType(false);
        setOpenHistoryDlg(true);
    };

    const handleNextClick = async (value) => {
        setLoading(true);
        switch (step) {
            case 0:
                await handleDataLoader();
                await handleFindFld();
                await handleFillPersonnel();
                //await handleFindHistory(selectedPersonnal.id);
                break;
            default:
                break;
        }
        setLoading(false);
    };

    const handleFillPersonnel = async () => {
        let pers = selectedPersonnal;

        var militaryPart = pers.militaryPart;
        let elemMilitaryPart = militaryPartList.find(item => item.label === militaryPart);
        var militaryUnit = pers.militaryUnit;
        let elemMilitaryUnit = militaryUnitList.find(item => item.label === militaryUnit);
        var militaryCompany = pers.militaryCompany;
        let elemMilitaryCompany = militaryCompanyList.find(item => item.label === militaryCompany);
        var militaryPlatoon = pers.militaryPlatoon;
        let elemMilitaryPlatoon = militaryPlatoonList.find(item => item.label === militaryPlatoon);
        var militaryDepartment = pers.militaryDepartment;
        let elemMilitaryDepartment = militaryDepartmentList.find(item => item.label === militaryDepartment);
        var militaryRank = pers.militaryRankByState;
        let elemMilitaryRank = militaryRankList.find(item => item.label === militaryRank);

        setMilitaryPart(elemMilitaryPart);
        setMilitaryUnit(elemMilitaryUnit);
        setMilitaryCompany(elemMilitaryCompany);
        setMilitaryPlatoon(elemMilitaryPlatoon);
        setMilitaryDepartment(elemMilitaryDepartment);
        setMilitaryRank(elemMilitaryRank);

        handleSetDateOfBirthday(pers.birthday);

        formik.setFieldValue(model.surname.name, pers.surname);
        formik.setFieldValue(model.name.name, pers.name);
        formik.setFieldValue(model.patronymic.name, pers.patronymic);
        formik.setFieldValue(model.phoneNumber.name, pers.phoneNumber);

        formik.setFieldValue(model.militaryPart.autoCompleteCbx.name, elemMilitaryPart);
        formik.setFieldValue(model.militaryUnit.autoCompleteCbx.name, elemMilitaryUnit);
        formik.setFieldValue(model.militaryCompany.autoCompleteCbx.name, elemMilitaryCompany);
        formik.setFieldValue(model.militaryPlatoon.autoCompleteCbx.name, elemMilitaryPlatoon);
        formik.setFieldValue(model.militaryDepartment.autoCompleteCbx.name, elemMilitaryDepartment);
        formik.setFieldValue(model.militaryRank.autoCompleteCbx.name, elemMilitaryRank);

        if (pers.surname !== undefined ||
            pers.surname !== null || 
            pers.surname !== '') {
            setIsDisabledSurname(true);
        }
        if (pers.name !== undefined || 
            pers.name !== null || 
            pers.name !== '') {
            setIsDisabledName(true);
        }
        if (pers.patronymic !== undefined || 
            pers.patronymic !== null || 
            pers.patronymic !== '') {
            setIsDisabledPatronymic(true);
        }
        if (pers.birthday !== undefined || 
            pers.birthday !== null || 
            pers.birthday !== '') {
            setIsDisabledBirthday(true);
        }
        if (pers.phoneNumber !== undefined || 
            pers.phoneNumber !== null || 
            pers.phoneNumber !== '') {
            //setIsDisabledPhoneNumber(true);
        }
        if (elemMilitaryPart.id !== '') {
            setIsDisabledMilitaryPath(true);
        }
        if (elemMilitaryUnit.id !== '') {
            setIsDisabledMilitaryUnit(true);
        }
        if (elemMilitaryCompany.id !== '') {
            setIsDisabledMilitaryCompany(true);
        }
        if (elemMilitaryPlatoon.id !== '') {
            setIsDisabledMilitaryPlatoon(true);
        }
        if (elemMilitaryDepartment.id !== '') {
            setIsDisabledMilitaryDepartment(true);
        }
        if (elemMilitaryRank.id !== '') {
            setIsDisabledMilitaryRank(true);
        }

        await handleFillHistory();
    }
    const handlePersonnalClose = async (value) => {
        setOpenPersonnalDlg(false);
        if (value === undefined) return;
        selectedPersonnal = value;
    };
    
    const handleFillHistory = async () => {
        let event = selectedHistory.result;
        let history = selectedHistory.result.transferableEpicrisis[0];

        handleSetDataAction(history.dateOfVisits);
        handleSetDateOfEvacuation(history.dateOfEvacuation);
        handleSetDateOfInjury(event.dateOfInjury);

        var locationMedicalAssistence = history.locationMedicalAssistance ?? '-';
        let elemLocationMedicalAssistence = locationMedicalAssistenceList.find(item => item.label === locationMedicalAssistence);
        var locationWhereInjury = event.locationWhereInjury ?? '-';
        let elemLocationWhereInjury = locationWhereInjuryList.find(item => item.label === locationWhereInjury);
        var institutionIsLocation = event.locationWhere ?? '-';
        let elemInstitutionIsLocation = institutionIsLocationList.find(item => item.label === institutionIsLocation);

        formik.setFieldValue(model.locationMedicalAssistance.autoCompleteCbx.name, elemLocationMedicalAssistence);
        formik.setFieldValue(model.locationWhereInjury.autoCompleteCbx.name, elemLocationWhereInjury);
        formik.setFieldValue(model.institutionIsLocation.autoCompleteCbx.name, elemInstitutionIsLocation);
        formik.setFieldValue(model.circumstances.name, event.circumstances ?? '');
        formik.setFieldValue(model.helpProvided.name, history.helpProvided ?? '');
        formik.setFieldValue(model.diagnosis.name, history.diagnosis ?? '');
        formik.setFieldValue(model.recommendations.name, history.recommendations ?? '');


        if (history.dateOfVisits !== undefined || 
            history.dateOfVisits !== null ||
            history.dateOfVisits !== '') {
            setIsDisabledDateAction(true);
        }
        if (history.dateOfEvacuation !== undefined || 
            history.dateOfEvacuation !== null || 
            history.dateOfEvacuation !== '') {
            setIsDisabledDateOfEvacuation(true);
        }
        if (event.dateOfInjury !== undefined || 
            event.dateOfInjury !== null || 
            event.dateOfInjury !== '') {
            setIsDisabledDateOfInjury(true);
        }
        if (elemLocationMedicalAssistence.id !== '') {
            setIsDisabledLocationMedicalAssistance(true);
        }
        if (elemLocationWhereInjury.id !== '') {
            setIsDisabledLocationWhereInjury(true);
        }
        if (elemInstitutionIsLocation.id !== '') {
            setIsDisabledInstitutionIsLocation(true);
        }
        if (event.circumstances !== undefined || 
            event.circumstances !== null || 
            event.circumstances !== '') {
            setIsDisabledComplaint(true);
        }
        if (history.helpProvided !== undefined || 
            history.helpProvided !== null || 
            history.helpProvided !== '') {
            setIsDisabledAssistanceProvided(true);
        }
        if (history.diagnosis !== undefined || 
            history.diagnosis !== null || 
            history.diagnosis !== '') {
            setIsDisabledDiagnosis(true);
        }
        if (history.diagnosis !== undefined || 
            history.diagnosis !== null || 
            history.diagnosis !== '') {
            setIsDisabledDiagnosis(true);
        }
        if (history.recommendations !== undefined || 
            history.recommendations !== null || 
            history.recommendations !== '') {
            setIsDisabledRecommendations(true);
        }
    };
    const handleHistoryClose = async (value) => {
        setOpenHistoryDlg(false);
        if (value === undefined) return;
        selectedHistory = value;
    };


    const model = {
        header: {
            thema: fields.collection2,
            label: 'Переводной эпикриз',
        },
        surname: {
            name: 'surname',
        },
        name: {
            name: 'name',
        },
        patronymic: {
            name: 'patronymic',
        },
        birthday: {
            name: 'birthday',
        },
        phoneNumber: {
            name: 'phoneNumber',
        },
        militaryPart: {
            autoCompleteCbx: {
                name: 'militaryPart',
            },
        },
        militaryUnit: {
            autoCompleteCbx: {
                name: 'militaryUnit',
            },
        },
        militaryCompany: {
            autoCompleteCbx: {
                name: 'militaryCompany',
            },
        },
        militaryPlatoon: {
            autoCompleteCbx: {
                name: 'militaryPlatoon',
            },
        },
        militaryDepartment: {
            autoCompleteCbx: {
                name: 'militaryDepartment',
            },
        },
        militaryRank: {
            autoCompleteCbx: {
                name: 'militaryRank',
            },
        },
        dateAction: {
            name: 'dateAction',
        },
        dateOfEvacuation: {
            name: 'dateOfEvacuation',
        },
        dateOfInjury: {
            name: 'dateAction',
        },
        circumstances: {
            name: 'circumstances',
        },
        helpProvided: {
            name: 'helpProvided',
        },
        diagnosis: {
            name: 'diagnosis',
        },
        recommendations: {
            name: 'recommendations',
        },
        locationMedicalAssistance: {
            autoCompleteCbx: {
                name: 'locationMedicalAssistance',
            },
        },
        locationWhereInjury: {
            autoCompleteCbx: {
                name: 'locationWhereInjury',
            },
        },
        institutionIsLocation: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'institutionIsLocationEdit',
                label: 'Находился на лечении:',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'institutionIsLocation',
                label: 'Находился на лечении:',
                variant: 'outlined',
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        backBtn: {
            thema: buttons.collection1,
            icon: <UndoOutlinedIcon />,
            variant: 'contained',
            handleClick: () => handleBackClick(),
            value: 'Назад',
        },
        nextBtn: {
            thema: buttons.collection1,
            icon: <MonitorHeartOutlinedIcon />,
            variant: 'contained',
            handleClick: () => handleNextClick(),
            value: 'Далее',
        },
        insertBtn: {
            thema: buttons.collection1,
            icon: <MedicalServicesOutlinedIcon />,
            variant: 'contained',
            handleClick: () => handleBackClick(),
            value: 'Печать',
        },
        updateBtn: {
            thema: buttons.collection1,
            icon: <MedicalServicesOutlinedIcon />,
            variant: 'contained',
            handleClick: () => { },
            value: 'Обновление',
        },
        historyDlg: {
            dialogTitle: {
                title1: 'Найденные истории болезни:'
            },
            thema: dialogs.collection1
        },
    };
    let context = {
        findFld: '',
        surname: '',
        name: '',
        patronymic: '',
        birthday: '',
        phoneNumber: '',
        militaryUnit: '',
        militaryUnitEdit: '',
        division: '',
        divisionEdit: '',
        rota: '',
        rotaEdit: '',
        platoon: '',
        platoonEdit: '',
        department: '',
        departmentEdit: '',
        actualRank: '',
        actualRankEdit: '',
        institutionIsLocation: { id: '', label: '' },
        institutionIsLocationEdit: '',
        complaint: '',
        dateAction: null,
        dateOfEvacuation: null,
        dateOfInjury: null,
        locationMedicalAssistance: { id: '', label: '' },
        locationMedicalAssistanceEdit: '',
        circumstances: '',
        helpProvided: '',
        diagnosis: '',
        recommendations: '',
        locationWhereInjury: { id: '', label: '' },
        locationWhereInjuryEdit: '',
    };
    const validationSchema = Yup.object().shape({
    });

    
    const handleSetDataAction = (val) => {
        formik.setFieldValue(model.dateAction.name, ParsingByStringToDate(val));
    };
    const handleSetDateOfEvacuation = (val) => {
        formik.setFieldValue(model.dateOfEvacuation.name, ParsingByStringDayJsToDate(val));
    };
    const handleSetDateOfInjury = (val) => {
        formik.setFieldValue(model.dateOfInjury.name, ParsingByStringDayJsToDate(val));
    };
    const handleSetDateOfBirthday = (val) => {
        formik.setFieldValue(model.birthday.name, ParsingByStringToDate(val));
    }


    const handleFormAction = (values) => {
    };    

    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: validationSchema,
        initialValues: context,
    });
    return (
        <Grid
            sx={{
                mt: '15px',
            }}
            container
        >
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={0} lg={1} xl={1}
            />
            <Grid
                item xs={12} sm={12} md={12} lg={10} xl={10}
            >
                <form>
                    <Card
                        sx={{
                            mx: '5px',
                            py: '15px',
                            px: '10px',
                            borderRadius: '10px'
                        }}
                        variant="outlined"
                    >
                        <Grid
                            container spacing={2}
                        >
                            <Grid
                                style={{
                                    textAlign: 'center',
                                }}
                                item xs={12} sm={12} md={12} lg={12} xl={12}
                            >
                                <Typography
                                    variant="h4" color={model.header.thema.borderColor}
                                >
                                    {model.header.label}
                                </Typography>
                            </Grid>
                            <FindByParser
                                formik={formik} show={findFldOk}
                                handleKeyUp={handleFindFldKeyUp} handleClick={handleFindFldClick}
                            />
                            {step > 0
                                ? isView
                                    ? <>
                                    </>
                                    : <>
                                        <InfoByPersonnel
                                            formik={formik}

                                            isDisabledSurname={isDisabledSurname} isDisabledName={isDisabledName} isDisabledPatronymic={isDisabledPatronymic}
                                            isDisabledBirthday={isDisabledBirthday} isDisabledPhoneNumber={isDisabledPhoneNumber}
                                        />
                                        <InfoByMilitaryTicketFull
                                            formik={formik}

                                            isDisabledMilitaryPath={isDisabledMilitaryPath} isDisabledMilitaryUnit={isDisabledMilitaryUnit} isDisabledMilitaryCompany={isDisabledMilitaryCompany}
                                            isDisabledMilitaryPlatoon={isDisabledMilitaryPlatoon} isDisabledMilitaryDepartment={isDisabledMilitaryDepartment}
                                            isDisabledMilitaryRank={isDisabledMilitaryRank} isDisabledComplaint={isDisabledComplaint}

                                            militaryPartList={militaryPartJson} militaryPart={militaryPart} setMilitaryPart={setMilitaryPart} handleClickMilitaryPath={handleClickMilitaryPath} isEditMilitaryPath={isEditMilitaryPath}
                                            militaryUnitList={militaryUnitJson} militaryUnit={militaryUnit} setMilitaryUnit={setMilitaryUnit} handleClickMilitaryUnit={handleClickMilitaryUnit} isEditMilitaryUnit={isEditMilitaryUnit}
                                            militaryCompanyList={militaryCompanyJson} militaryCompany={militaryCompany} setMilitaryCompany={setMilitaryCompany} handleClickMilitaryCompany={handleClickMilitaryCompany} isEditMilitaryCompany={isEditMilitaryCompany}
                                            militaryPlatoonList={militaryPlatoonJson} militaryPlatoon={militaryPlatoon} setMilitaryPlatoon={setMilitaryPlatoon} handleClickMilitaryPlatoon={handleClickMilitaryPlatoon} isEditMilitaryPlatoon={isEditMilitaryPlatoon}
                                            militaryDepartmentList={militaryDepartmentJson} militaryDepartment={militaryDepartment} setMilitaryDepartment={setMilitaryDepartment} handleClickMilitaryDepartment={handleClickMilitaryDepartment} isEditMilitaryDepartment={isEditMilitaryDepartment}
                                            militaryRankList={militaryRankJson} militaryRank={militaryRank} setMilitaryRank={setMilitaryRank} handleClickMilitaryRank={handleClickMilitaryRank} isEditMilitaryRank={isEditMilitaryRank}
                                        />
                                        <InfoByDoc
                                            formik={formik}

                                            isDisabledInstitutionIsLocation={isDisabledInstitutionIsLocation} isDisabledDateAction={isDisabledDateAction}
                                            isDisabledDateOfEvacuation={isDisabledDateOfEvacuation} isDisabledComplaint={isDisabledComplaint}
                                            isDisabledDateOfInjury={isDisabledDateOfInjury} // isDisabledInstitutionIsLocation={isDisabledInstitutionIsLocation}
                                            isDisabledCircumstances={isDisabledCircumstances} isDisabledAssistanceProvided={isDisabledAssistanceProvided}
                                            isDisabledDiagnosis={isDisabledDiagnosis} isDisabledRecommendations={isDisabledRecommendations}
                                            isDisabledLocationMedicalAssistance={isDisabledLocationMedicalAssistance}

                                            locationMedicalAssistanceJson={locationMedicalAssistanceJson} locationMedicalAssistance={locationMedicalAssistance} isEditLocationMedicalAssistance={isEditLocationMedicalAssistance}
                                            setLocationMedicalAssistance={setLocationMedicalAssistance} handleClickLocationMedicalAssistance={handleClickLocationMedicalAssistance}
                                            locationWhereInjuryJson={locationWhereInjuryJson} locationWhereInjury={locationWhereInjury} isEditLocationWhereInjurye={isEditLocationWhereInjury}
                                            setLocationWhereInjury={setLocationWhereInjury} handleClickLocationWhereInjury={handleClickLocationWhereInjury}
                                            institutionIsLocationJson={institutionIsLocationJson} institutionIsLocation={institutionIsLocation} isEditInstitutionIsLocation={isEditInstitutionIsLocation}
                                            setInstitutionIsLocation={setInstitutionIsLocation} handleClickInstitutionIsLocation={handleClickInstitutionIsLocation}
                                        />
                                    </>
                                : <></>
                            }
                            <Grid
                                item xs={5} sm={5} md={2} lg={3} xl={3}
                            >
                                <StartIconBtn
                                    thema={model.backBtn.thema} icon={model.backBtn.icon} variant={model.backBtn.variant}
                                    value={model.backBtn.value}
                                    handleClick={model.backBtn.handleClick}
                                />
                            </Grid>
                            <Grid
                                item xs={2} sm={2} md={8} lg={6} xl={6}
                            />
                            <Grid
                                item xs={5} sm={5} md={2} lg={3} xl={3}
                            >
                                {loading === true ?
                                    <CircularRightPrg /> :
                                    step === 1 ?
                                        isUpdate ?
                                            <EndIconBtn
                                                thema={model.updateBtn.thema} icon={model.updateBtn.icon} variant={model.updateBtn.variant}
                                                value={model.updateBtn.value}
                                                handleClick={model.updateBtn.handleClick}
                                            /> :
                                            <EndIconBtn
                                                thema={model.insertBtn.thema} icon={model.insertBtn.icon} variant={model.insertBtn.variant}
                                                value={model.insertBtn.value}
                                                handleClick={model.insertBtn.handleClick}
                                            /> :
                                        <EndIconBtn
                                            thema={model.nextBtn.thema} icon={model.nextBtn.icon} variant={model.nextBtn.variant}
                                            value={model.nextBtn.value}
                                            handleClick={model.nextBtn.handleClick}
                                        />
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </form>
            </Grid>
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={0} lg={1} xl={1}
            />
            <PersonnalDlg
                thema={model.historyDlg.thema} dialogTitle={model.historyDlg.dialogTitle} elems={personnels}
                selectedValue={selectedPersonnal} open={openPersonnalDlg} onClose={handlePersonnalClose} type={personnalDlgType}
            />
            <HistoryDlg
                thema={model.historyDlg.thema} dialogTitle={model.historyDlg.dialogTitle} elems={historys}
                selectedValue={selectedHistory} open={openHistoryDlg} onClose={handleHistoryClose} type={historyDlgType}
            />
        </Grid>
    )
}
