import React, { useState } from 'react';
import { fields } from '../app/thema';

import { Grid } from '@mui/material';
import { TextFld, DateFld, PhoneFld } from '../components/Fields';

import { ParsingByStringToDate, ParsingByStringDayJsToDate } from '../common/helper/Parser';


/**
 * Виджет форм ввода данных о военнослжащем
 * @param { Object } props передаваемые параметры
 * @returns виджет
 */
export default function InfoByPersonnel(props) {
    const model = {
        surname: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'surname',
            label: '*Фамилия',
        },
        name: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'name',
            label: '*Имя',
        },
        patronymic: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'patronymic',
            label: 'Отчество',
        },
        birthday: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'birthday',
            label: 'Дата рождения',
        },
        phoneNumber: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'phoneNumber',
            label: 'Контактный телефон',
            mask: '+7(999)999-99-99'
        }
    }

    const handleBirthdayChange = (val) => {
        props.formik.setFieldValue(model.birthday.name, ParsingByStringToDate(val));
    }

    return (
        <>
            <Grid
                item xs={12} sm={12} md={6} lg={6} xl={4}
            >
                <TextFld
                    thema={model.surname.thema} name={model.surname.name} size={model.surname.size}
                    type={model.surname.type} variant={model.surname.variant} label={model.surname.label}
                    value={props.formik.values.surname}
                    isDisabled={props.isDisabledSurname}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.surname} error={props.formik.errors.surname}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={6} lg={6} xl={4}
            >
                <TextFld
                    thema={model.name.thema} name={model.name.name} size={model.name.size}
                    type={model.name.type} variant={model.name.variant} label={model.name.label}
                    value={props.formik.values.name}
                    isDisabled={props.isDisabledName}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.name} error={props.formik.errors.name}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={6} lg={6} xl={4}
            >
                <TextFld
                    thema={model.patronymic.thema} name={model.patronymic.name} size={model.patronymic.size}
                    type={model.patronymic.type} variant={model.patronymic.variant} label={model.patronymic.label}
                    value={props.formik.values.patronymic}
                    isDisabled={props.isDisabledPatronymic}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.patronymic} error={props.formik.errors.patronymic}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={6} lg={6} xl={6}
            >
                <DateFld
                    thema={model.birthday.thema} size={model.birthday.size} type={model.birthday.type}
                    variant={model.birthday.variant} name={model.birthday.name} label={model.birthday.label}
                    value={props.formik.values.birthday} isDisabled={props.isDisabledBirthday}
                    handleBlur={props.formik.handleBlur} handleChange={handleBirthdayChange}
                    touched={props.formik.touched.birthday} error={props.formik.errors.birthday}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={12} xl={6}
            >
                <PhoneFld
                    thema={model.phoneNumber.thema} size={model.phoneNumber.size} type={model.phoneNumber.type}
                    variant={model.phoneNumber.variant} name={model.phoneNumber.name} label={model.phoneNumber.label}
                    mask={model.phoneNumber.mask}
                    value={props.formik.values.phoneNumber} isDisabled={props.isDisabledPhoneNumber}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.phoneNumber} error={props.formik.errors.phoneNumber}
                />
            </Grid>
        </>
    )
}
