import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";

import { fields, buttons, dialogs } from '../app/thema';
import { alertSuccess, alertWarn, alertError, alertInfo } from '../common/helper/ToastAlert';
import { urlHome } from '../common/helper/UrlRouter';
import { IsCode200, IsCode503 } from '../common/api/QueryHandler';

import { Grid, Card, Typography } from '@mui/material';


/**
 * Виджет формы ввода документа выписки в строй
 * @param { Object } props передаваемые параметры
 * @returns виджет
 */
export default function DocByMilitaryDischarge(props) {
    const [step, setStep] = useState(0);

    const handleFormAction = (values) => {
    };
    
    const model = {
        header: {
            thema: fields.collection2,
            label: 'Амбулаторная карта',
        },
        
    }
    let context = {
        
    }
    const validationSchema = Yup.object().shape({
    });


    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: validationSchema,
        initialValues: context
    });

    return (
        <Grid
            sx={{
                mt: '15px',
            }}
            container
        >
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={0} lg={1} xl={1}
            />
            <Grid
                item xs={12} sm={12} md={12} lg={10} xl={10}
            >
                <form>
                    <Card
                        sx={{
                            mx: '5px',
                            py: '15px',
                            px: '10px',
                            borderRadius: '10px'
                        }}
                        variant="outlined"
                    >
                        <Grid
                            container spacing={2}
                        >
                            <Grid
                                style={{
                                    textAlign: 'center',
                                }}
                                item xs={12} sm={12} md={12} lg={12} xl={12}
                            >
                                <Typography
                                    variant="h4" color={model.header.thema.borderColor}
                                >
                                    {model.header.label}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Card>
                </form>
            </Grid>
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={0} lg={1} xl={1}
            />            
        </Grid>
    )
}