import React from 'react';

import {
    Box, AppBar, Toolbar, IconButton, Typography
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';


/**
 * Виджет меню
 * @param { Object } props передаваемые параметры
 * @returns виджет
 */
export default function AppBarMenu(props) {

    const model = {
        appBar: {
            background: '#778D45',
            minHeight: '48px !important',
            flexGrow: 1,
            marginLeft: 'auto',
            position: 'static',
            img: {
                src: '/img/logo128.png',
                marginRight: '1em',
                width: '38px',
            },
            header: {
                alignItems: 'center',
                display: 'flex',
                variant: 'h6',
                component: 'div',
            },
        }
    }

    return (
        <Box
            sx={{
                flexGrow: model.appBar.flexGrow
            }}>
            <AppBar
                position={model.appBar.position}>
                <Toolbar
                    sx={{
                        background: model.appBar.background,
                        minHeight: model.appBar.minHeight,
                    }}>
                    <IconButton
                        sx={{ 
                            mr: 2 
                        }}
                        size="large" edge="start" color="inherit" aria-label="menu" 
                        onClick={props.handleClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        sx={{
                            alignItems: model.appBar.header.alignItems,
                            display: model.appBar.header.display,
                        }}
                        variant={model.appBar.header.variant} component={model.appBar.header.component}
                    >
                        {props.headerValue}
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    )
}
