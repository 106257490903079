import React from 'react';
import {
    Link, Typography
} from '@mui/material';
import BuildCircle from '@mui/icons-material/BuildCircleOutlined';


const style = {
    link: {
        display: 'flex',
        underline: 'none'
    }
}


/**
 * Компонент ссылка на версию продукта 
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function Version(props) {
    // &ensp;
    // &emsp;
    return (
        <Link
            sx={{
                display: style.link.display,
            }}
            href={props.href}
            underline={style.link.underline}
        >
            <BuildCircle
                sx={{
                    color: props.thema.color,
                }}
                fontSize={props.size}
            />&ensp;
            <Typography
                sx={{
                    fontSize: props.fontSize,
                    color: props.thema.color,
                }}
            >
                {props.value}
            </Typography>
        </Link>
    )
}


export{
    Version,
}