import React from 'react'
import { fields } from '../app/thema';
import { Grid } from '@mui/material';
import { FindFld } from '../components/Fields';


/**
 * 
 * @param {*} props 
 * @returns 
 */
function TokenNumber(props) {
    const model = {
        tokenNumber: {
            thema: fields.collection2,
            size: 'small',
            variant: 'outlined',
            name: 'tokenNumber',
            label: '*Номер жетона',
        },
    }

    return (
        <Grid
            item xs={12} sm={12} md={12} lg={12} xl={12}
        >
            <FindFld
                thema={model.tokenNumber.thema} name={model.tokenNumber.name} size={model.tokenNumber.size}
                variant={model.tokenNumber.variant} label={model.tokenNumber.label} value={props.formik.values.tokenNumber}
                show={props.tokenNumberOk}
                handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange} handleKeyUp={props.handleKeyUp}
                handleClick={props.handleClick}
                touched={props.formik.touched.tokenNumber} error={props.formik.errors.tokenNumber}
            />
        </Grid>
    )
}


export {
    TokenNumber
}