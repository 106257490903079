import { urlDef } from './UrlRouter';


/**
 * Проверка авторизации пользователя
 * @param { Object } navigate навигация
 */
function UserCheck(navigate) {
    let authentication = sessionStorage.getItem('authentication');
    if (authentication === null) navigate(urlDef);
}


export {
    UserCheck,
}