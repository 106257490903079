import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    data: {
        dateReception: '',
        doctors: '',
        tokenNumber: '',
        surname: '',
        name: '',
        patronymic: '',
        birthday: '',
        militaryRank: '',
        jobTitle: '',
        militaryUnit: '',
        battalion: '',
        rota: '',
        wzvod: '',
        location: '',
        category: '',
        phoneNumber: ''
    },
};

export const cardindexPersonnelSlice = createSlice({
    name: 'cardindexPersonnel',
    initialState,
    reducers: {
        setPersonnel: (state, action) => {
            state.data = action.payload;
        },
        clearPersonnel: (state) => {
            state.data = {
                dateReception: '',
                doctors: '',
                tokenNumber: '',
                surname: '',
                name: '',
                patronymic: '',
                birthday: '',
                militaryRank: '',
                jobTitle: '',
                militaryUnit: '',
                battalion: '',
                rota: '',
                wzvod: '',
                location: '',
                category: '',
                phoneNumber: '',
            };
        }
    },
})


export const { setPersonnel, clearPersonnel } = cardindexPersonnelSlice.actions

export default cardindexPersonnelSlice.reducer