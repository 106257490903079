import React, { useState } from 'react';
import {
    TextField, IconButton
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/ru';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import InputMask from 'react-input-mask';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { ruRU } from '@mui/x-date-pickers';


const style = {
    type: {
        text: 'text',
        date: 'date',
    }
}

const theme = createTheme(
    ruRU,
  );
/**
 * Компонент текстовое поле
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function TextFld(props) {
    return (
        <TextField
            sx={{
                backgroundColor: props.thema.backgroundColor,
                '& label.Mui-focused': {
                    color: props.thema.borderColor
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: props.thema.borderBottomColor
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: props.thema.borderColor
                    },
                    '&:hover fieldset': {
                        borderColor: props.thema.borderBottomColor
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: props.thema.borderColor
                    },
                },                
            }}
            required={!!props.required}
            fullWidth name={props.name} size={props.size} type={style.type.text} variant={props.variant}
            label={props.label} value={props.value} disabled={props.isDisabled}
            onBlur={props.handleBlur} onChange={props.handleChange} onClick={props.handleClickLabel}
            helperText={props.touched && props.error} error={Boolean(props.error && props.touched)}
        />
    )
}

/**
 * Компонент текстовое поле ввода даты
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function DateFld(props) {
    return (
        <TextField
            sx={{
                backgroundColor: props.thema.backgroundColor,
                '& label.Mui-focused': {
                    color: props.thema.color,
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: props.thema.borderBottomColor,
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: props.thema.borderColor,
                    },
                    '&:hover fieldset': {
                        borderColor: props.thema.borderColor,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: props.thema.borderColor,
                    },
                },
            }}
            InputLabelProps={{ shrink: true }}
            fullWidth name={props.name} size={props.size} type={style.type.date} variant={props.variant}
            label={props.label} value={props.value} disabled={props.isDisabled}
            onBlur={props.handleBlur} onChange={props.handleChange}
            helperText={props.touched && props.error} error={Boolean(props.error && props.touched)}
        />
    )
}

/**
 * Компонент текстовое поле ввода даты и времени
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function DateTimeFld(props) {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
            <DateTimePicker
                sx={{
                    backgroundColor: props.thema.backgroundColor,
                    '& label.Mui-focused': {
                        color: props.thema.color,
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: props.thema.borderBottomColor,
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: props.thema.borderColor,
                        },
                        '&:hover fieldset': {
                            borderColor: props.thema.borderColor,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: props.thema.borderColor,
                        },
                    },
                }}
                slotProps={{
                    textField: {
                        size: props.size,
                        fullWidth: true,
                    },
                    actionBar: {
                        actions: ['clear', 'accept']
                      }
                }}
                name={props.name} variant={props.variant} label={props.label}
                value={props.value != null ? dayjs(props.value) : null} disabled={props.isDisabled}
                onBlur={props.handleBlur} onChange={(val) => props.handleChange(dayjs(val))}
                helperText={props.touched && props.error} error={Boolean(props.error && props.touched)}
            />
        </LocalizationProvider>
        </ThemeProvider>
    )
}

/**
 * Компонент текстовое поле ввода даты и времени
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function DateTimeDesktopFld(props) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
            <DesktopDateTimePicker
                sx={{
                    backgroundColor: props.thema.backgroundColor,
                    '& label.Mui-focused': {
                        color: props.thema.color,
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: props.thema.borderBottomColor,
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: props.thema.borderColor,
                        },
                        '&:hover fieldset': {
                            borderColor: props.thema.borderColor,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: props.thema.borderColor,
                        },
                    },
                }}
                slotProps={{
                    textField: {
                        size: props.size,
                        fullWidth: true,
                    }
                }}
                name={props.name} variant={props.variant} label={props.label}
                value={props.value != null ? dayjs(props.value) : null} disabled={props.isDisabled}
                onBlur={props.handleBlur} onChange={(val) => props.handleChange(dayjs(val))}
                helperText={props.touched && props.error} error={Boolean(props.error && props.touched)}
            />
        </LocalizationProvider>
    )
}

/**
 * Компонент многострочное текстовое поле ввода
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function MultilineFld(props) {
    return (
        <TextField
            sx={{
                backgroundColor: props.thema.backgroundColor,
                '& label.Mui-focused': {
                    color: props.thema.color,
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: props.thema.borderBottomColor,
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: props.thema.borderColor,
                    },
                    '&:hover fieldset': {
                        borderColor: props.thema.borderColor,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: props.thema.borderColor,
                    },
                },
            }}
            fullWidth multiline name={props.name} size={props.size} type={style.type.text} variant={props.variant}
            label={props.label} value={props.value} rows={props.rows} disabled={props.isDisabled}
            onBlur={props.handleBlur} onChange={props.handleChange} onClick={props.handleClick}
            helperText={props.touched && props.error} error={Boolean(props.error && props.touched)}
        />
    )
}

/**
 * Компонент текстовое поле ввода пароля
 * @param { object } props передаваемые параметры в компонент
 * @returns компонент
 */
function PasswordFld(props) {
    const [show, setShow] = useState(false);

    const handleClickShowPassword = () => {
        setShow(!show);
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    return (
        <TextField
            sx={{
                backgroundColor: props.thema.backgroundColor,
                '& label.Mui-focused': {
                    color: props.thema.color,
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: props.thema.borderBottomColor,
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: props.thema.borderColor,
                    },
                    '&:hover fieldset': {
                        borderColor: props.thema.borderColor,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: props.thema.borderColor,
                    },
                },
            }}
            required={!!props.required}
            fullWidth name={props.name} size={props.size} type={show ? 'text' : 'password'}
            variant={props.variant} label={props.label} value={props.value} disabled={props.isDisabled}
            onBlur={props.handleBlur} onChange={props.handleChange}
            helperText={props.touched && props.error} error={Boolean(props.error && props.touched)}
            InputProps={{
                endAdornment:
                    <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {show ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
            }}
        />
    )
}

/**
 * Компонент текстовое поле ввода телефона
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function PhoneFld(props) {
    return (
        <InputMask
            mask={props.mask} value={props.value}
            onBlur={props.handleBlur} onChange={props.handleChange}
            helperText={props.touched && props.error} error={Boolean(props.error && props.touched)}
        >
            {() => <TextField
                sx={{
                    backgroundColor: props.thema.backgroundColor,
                    '& label.Mui-focused': {
                        color: props.thema.color,
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: props.thema.borderBottomColor,
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: props.thema.borderColor,
                        },
                        '&:hover fieldset': {
                            borderColor: props.thema.borderColor,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: props.thema.borderColor,
                        },
                    },
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth name={props.name} size={props.size} type={style.type.text} label={props.label}
            />}
        </InputMask>
    )
}

/**
 * Компонент текстовое поле ввода с поиском значения
 * @param { object } props передаваемые параметры в компонент
 * @returns компонент
 */
function FindFld(props) {
    return (
        <TextField
            sx={{
                backgroundColor: props.thema.backgroundColor,
                '& label.Mui-focused': {
                    color: props.thema.color,
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: props.thema.borderBottomColor,
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: props.thema.borderColor,
                    },
                    '&:hover fieldset': {
                        borderColor: props.thema.borderColor,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: props.thema.borderColor,
                    },
                },
            }}
            fullWidth name={props.name} size={props.size} type={style.type.text}
            variant={props.variant} label={props.label} value={props.value}
            disabled={props.isDisabled}
            onBlur={props.handleBlur} onChange={props.handleChange} onKeyUp={props.handleKeyUp}
            onClick={props.handleClick}
            helperText={props.touched && props.error} error={Boolean(props.error && props.touched)}
            InputProps={{
                endAdornment:
                    <IconButton
                        edge="end"
                    >
                        {props.show ? <TaskAltOutlinedIcon sx={{ color: props.thema.borderBottomColor }} /> : <></>}
                    </IconButton>
            }}
        />
    )
}


export {
    TextFld, DateFld, DateTimeFld, DateTimeDesktopFld, MultilineFld, PasswordFld, PhoneFld, FindFld,
}