import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router';

import { AlertToast } from '../components/Alerts';
import { UserCheck } from '../common/helper/Cheking';

import AppBarMenu from '../widgets/AppBarMenu';
import LeftMenu from '../widgets/LeftMenu';
import { History } from '../features/history';

import {
    setCurrentPatientId,
    sendGetPatientData,
} from "../features/history/historySlice";

/**
 * 
 * @returns 
 */
export default function PHistory(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuExportOpen, setMenuExportOpen] = useState(false);
    const [role, setRole] = useState('');
    console.log(params);

    useEffect(() => {
        UserCheck(navigate);
        let buf = JSON.parse(sessionStorage.getItem('authentication'));
        setRole(buf.role);
    }, []);

    useEffect(() => {
        dispatch(setCurrentPatientId(params.patientId));
        if (params.patientId) {
            dispatch(sendGetPatientData({ id: params.patientId }));
        }
    }, [dispatch, params.patientId]);

    const toggleDrawerOpen = () => {
        setMenuOpen(true);
    };
    const toggleDrawerClose = () => {
        setMenuOpen(false);
    };
    const handleMenuExport = () => {
        setMenuExportOpen(!menuExportOpen);
        console.log(menuExportOpen);
    }
    const model = {
        appBarMenu: {
            headerValue: 'Этапы эвакуации',
        },
    }

    return (
        <>
            <AppBarMenu
                headerValue={model.appBarMenu.headerValue} handleClick={toggleDrawerOpen}
            />
            <LeftMenu
                open={menuOpen} openMenuExport={menuExportOpen} role={role}
                handleClick={toggleDrawerClose} handleMenuExport={handleMenuExport}
            />
            <History />
            <AlertToast />
        </>
    )
}
