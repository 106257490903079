import React, { useState } from 'react';
import { fields, buttons, dialogs } from '../app/thema';

import { Grid, Card, Typography } from '@mui/material';

import { ComboBoxEdit } from './ComboBoxEdit';
import { TextFld, DateFld, DateTimeFld, DateTimeDesktopFld, MultilineFld } from '../components/Fields';

import { ParsingByStringToDate, ParsingByStringDayJsToDate } from '../common/helper/Parser';


/**
 * 
 * @param { Object } props передаваемые параметры
 * @returns виджет
 */
export default function InfoByDoc(props) {
    const model = {
        dateAction: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateAction',
            label: 'Время с',
        },
        institutionIsLocation: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'institutionIsLocationEdit',
                label: 'Находился на лечении:',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'institutionIsLocation',
                label: 'Находился на лечении:',
                variant: 'outlined',
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        dateOfInjury: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateAction',
            label: 'Время ранения',
        },
        complaint: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'complaint',
            label: 'Жалобы',
            rows: '3',
        },        
        dateOfEvacuation: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateOfEvacuation',
            label: 'Время по',
        },        
        locationMedicalAssistance: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'locationMedicalAssistanceEdit',
                label: 'Направляется в',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'locationMedicalAssistance',
                label: 'Направляется в',
                variant: 'outlined',
            },
            iconBtn: {
                thema: buttons.collection1,
            },
        },
        circumstances: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'circumstances',
            label: 'Обстоятельства ранения/болезни',
            rows: '4',
        },
        assistanceProvided: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'assistanceProvided',
            label: 'Лечение',
            rows: '4',
        },
        diagnosis: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'diagnosis',
            label: 'Диагноз',
            rows: '4',
        },
        recommendations: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'recommendations',
            label: 'Рекомендации при выписке',
            rows: '4',
        },
    };

    const handleSetDataAction = (val) => {
        props.formik.setFieldValue(model.dateAction.name, ParsingByStringToDate(val));
    };

    const handleSetDateOfEvacuation = (val) => {
        props.formik.setFieldValue(model.dateOfEvacuation.name, ParsingByStringDayJsToDate(val));
    };

    const handleSetDateOfInjury = (val) => {
        props.formik.setFieldValue(model.dateOfInjury.name, ParsingByStringDayJsToDate(val));
    };
    return (
        <>
            <Grid
                item xs={12} sm={12} md={12} lg={6} xl={6}
            >
                <ComboBoxEdit
                    model={model.institutionIsLocation} elems={props.institutionIsLocationJson} value={props.formik.values.institutionIsLocation}
                    valueEdit={props.formik.values.institutionIsLocationEdit} val={props.institutionIsLocation} 
                    isEdit={props.isEditInstitutionIsLocation} isDisabled={props.isDisabledInstitutionIsLocation}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setInstitutionIsLocation}
                    handleClick={props.handleClickInstitutionIsLocation}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.institutionIsLocation} touchedEdit={props.formik.touched.institutionIsLocationEdit}
                    error={props.formik.errors.institutionIsLocation} errorEdit={props.formik.errors.institutionIsLocationEdit}
                />
            </Grid>        
            <Grid
                item xs={12} sm={12} md={12} lg={3} xl={3}
            >
                <DateTimeDesktopFld
                    thema={model.dateAction.thema} name={model.dateAction.name} size={model.dateAction.size}
                    variant={model.dateAction.variant} label={model.dateAction.label}
                    value={props.formik.values.dateAction} 
                    isDisabled={props.isDisabledDateAction}
                    handleBlur={props.formik.handleBlur} handleChange={handleSetDataAction}
                    touched={props.formik.touched.dateAction} error={props.formik.errors.dateAction}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={3} xl={3}
            >
                <DateTimeDesktopFld
                    thema={model.dateOfEvacuation.thema} name={model.dateOfEvacuation.name} size={model.dateOfEvacuation.size}
                    type={model.dateOfEvacuation.type} variant={model.dateOfEvacuation.variant} label={model.dateOfEvacuation.label}
                    value={props.formik.values.dateOfEvacuation} isDisabled={props.isDisabledDateOfEvacuation}
                    handleBlur={props.formik.handleBlur} handleChange={handleSetDateOfEvacuation}
                    touched={props.formik.touched.dateOfEvacuation} error={props.formik.errors.dateOfEvacuation}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={12} xl={12}
            >
                <MultilineFld
                    thema={model.complaint.thema} size={model.complaint.size} type={model.complaint.type}
                    variant={model.complaint.variant} name={model.complaint.name} label={model.complaint.label}
                    rows={model.complaint.rows} value={props.formik.values.complaint} isDisabled={props.isDisabledComplaint}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.complaint} error={props.formik.errors.complaint}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={4} xl={4}
            >
                <DateTimeDesktopFld
                    thema={model.dateOfInjury.thema} name={model.dateOfInjury.name} size={model.dateOfInjury.size}
                    variant={model.dateOfInjury.variant} label={model.dateOfInjury.label}
                    value={props.formik.values.dateOfInjury} 
                    isDisabled={props.isDisabledDateOfInjury}
                    handleBlur={props.formik.handleBlur} handleChange={handleSetDateOfInjury}
                    touched={props.formik.touched.dateOfInjury} error={props.formik.errors.dateOfInjury}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={8} xl={8}
            >
                <ComboBoxEdit
                    model={model.institutionIsLocation} elems={props.institutionIsLocationJson} 
                    value={props.formik.values.institutionIsLocation} valueEdit={props.formik.values.institutionIsLocationEdit} 
                    val={props.institutionIsLocation} 
                    isEdit={props.isEditInstitutionIsLocation} isDisabled={props.isDisabledInstitutionIsLocation}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setInstitutionIsLocation} 
                    handleClick={props.handleClickInstitutionIsLocation}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.institutionIsLocation} touchedEdit={props.formik.touched.institutionIsLocationEdit}
                    error={props.formik.errors.institutionIsLocation} errorEdit={props.formik.errors.institutionIsLocationEdit}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={6} xl={6}
            >
                <MultilineFld
                    thema={model.circumstances.thema} size={model.circumstances.size} type={model.circumstances.type}
                    variant={model.circumstances.variant} name={model.circumstances.name} label={model.circumstances.label}
                    rows={model.circumstances.rows} value={props.formik.values.circumstances} isDisabled={props.isDisabledCircumstances}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.circumstances} error={props.formik.errors.circumstances}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={6} xl={6}
            >
                <MultilineFld
                    thema={model.assistanceProvided.thema} size={model.assistanceProvided.size} type={model.assistanceProvided.type}
                    variant={model.assistanceProvided.variant} name={model.assistanceProvided.name} label={model.assistanceProvided.label}
                    rows={model.assistanceProvided.rows} value={props.formik.values.assistanceProvided} isDisabled={props.isDisabledAssistanceProvided}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.assistanceProvided} error={props.formik.errors.assistanceProvided}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={6} xl={6}
            >
                <MultilineFld
                    thema={model.diagnosis.thema} size={model.diagnosis.size} type={model.diagnosis.type}
                    variant={model.diagnosis.variant} name={model.diagnosis.name} label={model.diagnosis.label}
                    rows={model.diagnosis.rows} value={props.formik.values.diagnosis} isDisabled={props.isDisabledDiagnosis}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.diagnosis} error={props.formik.errors.diagnosis}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={6} xl={6}
            >
                <MultilineFld
                    thema={model.recommendations.thema} size={model.recommendations.size} type={model.recommendations.type}
                    variant={model.recommendations.variant} name={model.recommendations.name} label={model.recommendations.label}
                    rows={model.recommendations.rows} value={props.formik.values.recommendations} isDisabled={props.isDisabledRecommendations}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.recommendations} error={props.formik.errors.recommendations}
                />
            </Grid>            
            <Grid
                item xs={12} sm={12} md={12} lg={12} xl={12}
            >
                <ComboBoxEdit
                    model={model.locationMedicalAssistance} elems={props.locationMedicalAssistanceJson} 
                    value={props.formik.values.locationMedicalAssistance} valueEdit={props.formik.values.locationMedicalAssistanceEdit} 
                    val={props.locationMedicalAssistance} 
                    isEdit={props.isEditLocationMedicalAssistance} isDisabled={props.isDisabledLocationMedicalAssistance}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setLocationMedicalAssistance} 
                    handleClick={props.handleClickLocationMedicalAssistance}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.locationMedicalAssistance} touchedEdit={props.formik.touched.locationMedicalAssistanceEdit}
                    error={props.formik.errors.locationMedicalAssistance} errorEdit={props.formik.errors.locationMedicalAssistanceEdit}
                />
            </Grid>            
        </>
    )
}
