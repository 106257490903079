import React, { useState } from 'react';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";

import { fields, buttons, dialogs } from '../app/thema';
import { alertSuccess, alertWarn, alertError, alertInfo } from '../common/helper/ToastAlert';
import { urlPatientRegistration } from '../common/helper/UrlRouter';
import { IsCode200, IsCode503 } from '../common/api/QueryHandler';
import {
    RegPersonnelAsync, FindByTokenNumberAsync, FindByCallSignAsync
} from '../common/api/Personnel';
import {
    Get300Async, Reg300Async, Upd300Async
} from '../common/api/RegForms';
import { MilitaryHandbooksAsync, Handbook300Async } from '../common/api/Handbook';

import { Grid, Card, Typography } from '@mui/material';

import { DateTimeDesktopFld, MultilineFld } from '../components/Fields';
import { AutoCompleteCbx } from '../components/ComboBoxs';
import { StartIconBtn, EndIconBtn } from '../components/Buttons';
import { CircularRightPrg } from '../components/Progress';
import { PersonnalDlg } from '../components/Dialogs';

import { TokenNumber } from './TokenNumber';
import { CallSign } from './CallSign';
import { FullNameInput } from './FullNameInput';
import { FullNameShow } from './FullNameShow';
import { ComboBoxEdit } from './ComboBoxEdit';

import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';

import gravityOfInjuryJson from '../shared/gravityOfInjury.json';


/**
 * Виджет формы регистрации пациента код 300
 * @returns виджет
 */
export default function FormaByPatient300() {
    const navigate = useNavigate();

    let isValid = true;
    let isOnline = true;
    let isDiagnosisSelected = false;

    const [tokenNumberOk, setTokenNumberOk] = useState(false);
    const [isVisibleCallSign, setIsVisibleCallSign] = useState(true);
    const [callSignOk, setCallSignOk] = useState(false);
    const [isVisibleFullName, setisVisibleFullName] = useState(false);
    const [fullNameValue, setFullNameValue] = useState('');
    const [step, setStep] = useState(0);
    const [open, setOpen] = useState(false);
    const [personnalDlgType, setPersonnalDlgType] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const [gravityOfInjury, setGravityOfInjury] = useState(gravityOfInjuryJson[0]);
    const [personnels, setPersonnels] = useState([]);
    const [selectedValue, setSelectedValue] = useState({});
    const [idEvent, setIdEvent] = useState('');
    const [idHistory, setIdHistory] = useState('');

    const [militaryPartJson, setMilitaryPartJson] = useState();
    var militaryPartList = [];
    const [militaryUnitJson, setMilitaryUnitJson] = useState();
    var militaryUnitList = [];
    const [militaryCompanyJson, setMilitaryCompanyJson] = useState();
    var militaryCompanyList = [];
    const [militaryPlatoonJson, setMilitaryPlatoonJson] = useState();
    var militaryPlatoonList = [];
    const [militaryDepartmentJson, setMilitaryDepartmentJson] = useState();
    var militaryDepartmentList = [];
    const [militaryRankJson, setMilitaryRankJson] = useState();
    var militaryRankList = [];
    const [institutionIsLocationJson, setInstitutionIsLocationJson] = useState();
    var institutionIsLocationList = [];
    const [locationMedicalAssistanceJson, setLocationMedicalAssistanceJson] = useState();
    var locationMedicalAssistanceList = [];
    const [locationWhereInjuryJson, setLocationWhereInjuryJson] = useState();
    var locationWhereInjuryList = [];

    const [militaryPart, setMilitaryPart] = useState({ id: '', label: '' });
    const [militaryUnit, setMilitaryUnit] = useState({ id: '', label: '' });
    const [militaryCompany, setMilitaryCompany] = useState({ id: '', label: '' });
    const [militaryPlatoon, setMilitaryPlatoon] = useState({ id: '', label: '' });
    const [militaryDepartment, setMilitaryDepartment] = useState({ id: '', label: '' });
    const [militaryRank, setMilitaryRank] = useState({ id: '', label: '' });
    const [locationWhereInjury, setLocationWhereInjury] = useState({ id: '', label: '' });
    const [locationMedicalAssistance, setLocationMedicalAssistance] = useState({ id: '', label: '' });
    const [institutionIsLocation, setInstitutionIsLocation] = useState({ id: '', label: '' });

    const [isEditMilitaryPath, setIsEditMilitaryPath] = useState(false);
    const [isEditMilitaryUnit, setIsEditMilitaryUnit] = useState(false);
    const [isEditMilitaryCompany, setIsEditMilitaryCompany] = useState(false);
    const [isEditMilitaryPlatoon, setIsEditMilitaryPlatoon] = useState(false);
    const [isEditMilitaryDepartment, setIsEditMilitaryDepartment] = useState(false);
    const [isEditMilitaryRank, setIsEditMilitaryRank] = useState(false);
    const [isEditLocationWhereInjury, setIsEditLocationWhereInjury] = useState(false);
    const [isEditLocationMedicalAssistance, setIsEditLocationMedicalAssistance] = useState(false);
    const [isEditInstitutionIsLocation, setIsEditInstitutionIsLocation] = useState(false);

    const dtNotValid = 'Выбранная вами дата и время, еще не наступили!';
    const dtActionNotValid = 'Выбранная вами дата и время, не может быть раньше даты и времени ранения!';

    const handleSetBirthday = () => {
        let year = (new Date().getFullYear()) - 30;
        let dt = new Date(year, 1).toISOString();
        dt = dt.substring(0, dt.indexOf('T'));
        formik.setFieldValue(model.birthday.name, dt);
    }
    const handleSetDataAction = (val) => {
        let dt1 = new Date(dayjs(val ?? undefined));
        let dtNow = new Date();
        if (dt1 > dtNow) {
            dt1 = dtNow;
            setDateAction(dt1);
            alertInfo(dtNotValid);
            return;
        }

        let dt2 = new Date(dayjs(formik.values.dateOfInjury ?? undefined));
        if (dt1 < dt2) {
            setDateAction(dtNow);
            alertInfo(dtActionNotValid);
            return;
        }

        setDateAction(dt1);
    }
    const setDateAction = (val) => {
        val = val.getFullYear() === 1 ? new Date() : val.toISOString();
        formik.setFieldValue(model.dateAction.name, val);
    }
    const handleSetDateOfInjury = (val) => {
        val = new Date(dayjs(val ?? undefined));
        let dtNow = new Date();
        if (val > dtNow) {
            val = dtNow;
            setDateOfInjury(val);
            alertInfo(dtNotValid);
            return;
        }
        setDateOfInjury(val);
    }
    const setDateOfInjury = (val) => {
        val = val.getFullYear() === 1 ? new Date() : val.toISOString();
        formik.setFieldValue(model.dateOfInjury.name, val);
    }
    const handleSetDateOfEvacuation = (val) => {
        let isValid = dayjs(val).isValid();
        // Валидация
        if (isValid) {
            let tmp = new Date(val);
            val = tmp.getFullYear() === 1 ? val : tmp.toISOString();
        }
        formik.setFieldValue(model.dateOfEvacuation.name, val);
    }

    // События поля номер жетона
    const isTokenNumber = (fl) => {
        if (fl) {
            // Номер жетона был найден
            setTokenNumberOk(true);
            setIsVisibleCallSign(false);
            setCallSignOk(false);
            setisVisibleFullName(false);
            setStep(2);

            return;
        }

        // Номер жетона не был найден
        setTokenNumberOk(false);
        setIsVisibleCallSign(true);
        setCallSignOk(false);
        setStep(1);
    }
    const handleTokenNumberKeyUp = (e) => {
        let code = e.keyCode || 0;
        if (code !== 13) return;

        handleNextClick();
    }
    const handleTokenNumberClick = () => {
        setTokenNumberOk(false);
        setStep(0);
    }

    const handleTokenNumber = async () => {
        handleSetBirthday();
        handleSetDataAction();
        handleSetDateOfInjury(new Date());
        let res = await FindByTokenNumberAsync(formik.values.tokenNumber);
        // Валидация запроса
        if (!IsCode200(res)) {
            isTokenNumber(false);

            return;
        }

        setPersonnels(res.result);
        if (res.result.length === 1) {
            setSelectedValue(res.result[0]);
            setFullNameValue(`${res.result[0].fullName}`);
            await handleFillData(res.result[0].id);
            isTokenNumber(true);
            setLoading(false);

            return;
        }

        setPersonnalDlgType(true);
        setOpen(true);
    }

    // События поля позывной
    const isCallSign = (fl) => {
        if (fl) {
            // Позывной был найден
            setCallSignOk(true);
            setisVisibleFullName(false);
            setStep(2);

            return;
        }

        // Позывной не был найден
        setCallSignOk(false);
        setisVisibleFullName(true);
        setStep(2);
    }

    const handleCallSignKeyUp = async (e) => {
        if (e.keyCode !== 13) return;

        handleNextClick();
    }

    const handleCallSignClick = () => {
        setCallSignOk(false);
        setStep(1);
    }

    const handleCallSign = async () => {
        let res = await FindByCallSignAsync(formik.values.callSign);
        // Валидация запроса
        if (!IsCode200(res)) {
            isCallSign(false);

            return;
        }

        setPersonnels(res.result);
        if (res.result.length === 1) {
            setSelectedValue(res.result);
            setFullNameValue(`${res.result[0].fullName}`);
            await handleFillData(res.result[0].id);
            isCallSign(true);

            return;
        }

        setPersonnalDlgType(false);
        setOpen(true);
    }

    // Прочие события
    const handleClick = () => {
        navigate(urlPatientRegistration);
    }

    const handlerSetMilitaryPartList = (val) => {
        militaryPartList = val;
        setMilitaryPartJson(val);
        if (formik.values.militaryUnit === '') {
            setMilitaryPart(val[0]);
        }
    }
    const handleClickMilitaryPath = () => {
        setIsEditMilitaryPath(!isEditMilitaryPath);
    }

    const handlerSetMilitaryUnitList = (val) => {
        militaryUnitList = val;
        setMilitaryUnitJson(val);
        if (formik.values.division === '') {
            setMilitaryUnit(val[0]);
        }
    }
    const handleClickMilitaryUnit = () => {
        setIsEditMilitaryUnit(!isEditMilitaryUnit);
    }

    const handlerSetCompanyList = (val) => {
        militaryCompanyList = val;
        setMilitaryCompanyJson(val);
        if (formik.values.rota === '') {
            setMilitaryCompany(val[0]);
        }
    }
    const handleClickMilitaryCompany = () => {
        setIsEditMilitaryCompany(!isEditMilitaryCompany);
    }

    const handlerSetMilitaryPlatoonList = (val) => {
        militaryPlatoonList = val;
        setMilitaryPlatoonJson(val);
        if (formik.values.platoon === '') {
            setMilitaryPlatoon(val[0]);
        }
    }
    const handleClickMilitaryPlatoon = () => {
        setIsEditMilitaryPlatoon(!isEditMilitaryPlatoon);
    }

    const handlerSetMilitaryDepartmentList = (val) => {
        militaryDepartmentList = val;
        setMilitaryDepartmentJson(val);
        if (formik.values.department === '') {
            setMilitaryDepartment(val[0]);
        }
    }
    const handleClickMilitaryDepartment = () => {
        setIsEditMilitaryDepartment(!isEditMilitaryDepartment);
    }

    const handlerSetMilitaryRankList = (val) => {
        militaryRankList = val;
        setMilitaryRankJson(val);
        if (formik.values.actualRank === '') {
            setMilitaryRank(val[0]);
        }
    }
    const handleClickMilitaryRank = () => {
        setIsEditMilitaryRank(!isEditMilitaryRank);
    }

    const handlerSetLocationMedicalAssistanceList = (val) => {
        locationMedicalAssistanceList = val;
        setLocationMedicalAssistanceJson(val);
        if (formik.values.locationMedicalAssistance?.label === '') {
            setLocationMedicalAssistance(val[0]);
        }
    }
    const handleClickLocationMedicalAssistance = () => {
        setIsEditLocationMedicalAssistance(!isEditLocationMedicalAssistance);
    }

    const handlerSetLocationWhereInjuryList = (val) => {
        locationWhereInjuryList = val;
        setLocationWhereInjuryJson(val);
        if (formik.values.locationWhereInjury?.label === '') {
            setLocationWhereInjury(val[0]);
        }
    }
    const handleClickLocationWhereInjury = () => {
        setIsEditLocationWhereInjury(!isEditLocationWhereInjury);
    }

    const handleClickDiagnosis = (event) => {
        let diagnosis = formik.values.diagnosis.trim();
        let natureOfDiagnosis = formik.values.natureOfDiagnosis.trim();
        if (!isDiagnosisSelected && diagnosis === '' && natureOfDiagnosis !== '') {
            isDiagnosisSelected = true;
            formik.setFieldValue(model.diagnosis.name, formik.values.natureOfDiagnosis);
        }
    }


    const handlerSetInstitutionIsLocationList = (val) => {
        institutionIsLocationList = val;
        setInstitutionIsLocationJson(val);
        if (formik.values.institutionIsLocation?.label === '') {
            setInstitutionIsLocation(val[0]);
        }
    }
    const handleClickInstitutionIsLocation = () => {
        setIsEditInstitutionIsLocation(!isEditInstitutionIsLocation);
    }
    const handleChangeInstitutionIsLocation = (event, val) => {
        formik.setFieldValue(event, val);

        // если дата ещё не установлена, ставим текущую
        if (val !== undefined &&
            val?.label !== '-' &&
            val?.label !== ' ') {
            handleSetDateOfEvacuation(new Date().toISOString());

            return;
        }

        formik.setFieldValue(model.dateOfEvacuation.name, null);
    }
    const handleChangeInstitutionIsLocationEdit = (event) => {
        let val = event.target.value;
        formik.setFieldValue(model.institutionIsLocation.textFld.name, val);
        if (val.trim() !== '-' &&
            val.trim() !== '') {
            handleSetDateOfEvacuation(new Date().toISOString());

            return;
        }

        formik.setFieldValue(model.dateOfEvacuation.name, null);
    }

    const handlerDataLoader = async () => {
        let handbook = await MilitaryHandbooksAsync();
        let handbook300 = await Handbook300Async();

        if (handbook.result) {
            handlerSetMilitaryPartList(handbook.result.militaryPart);
            handlerSetMilitaryUnitList(handbook.result.militaryUnit);
            handlerSetCompanyList(handbook.result.militaryCompany);
            handlerSetMilitaryPlatoonList(handbook.result.militaryPlatoon);
            handlerSetMilitaryDepartmentList(handbook.result.militaryDepartment);
            handlerSetMilitaryRankList(handbook.result.militaryRank);
        }

        if (handbook300.result) {
            handlerSetLocationWhereInjuryList(handbook300.result.locationWhereInjury);
            handlerSetInstitutionIsLocationList(handbook300.result.locationWhere);
            handlerSetLocationMedicalAssistanceList(handbook300.result.locationWhere);
        }
    }

    const handleNextClick = async () => {
        setLoading(true);
        switch (step) {
            case 0:

                let tokenToCheck = formik.values.tokenNumber;
                tokenToCheck = tokenToCheck.replace(/\s/g, '');
                tokenToCheck = tokenToCheck.replace(/-/g, '');

                if (tokenToCheck.match(/^[а-яА-ЯёЁ]{1,2}-?\d{6}$/gmi)) {
                    let letters = tokenToCheck.slice(0, -6).toUpperCase();
                    let number = tokenToCheck.slice(-6, 8);
                    let fixedTokenNumber = letters + '-' + number;

                    formik.setFieldValue("tokenNumber", fixedTokenNumber);
                    formik.setErrors({ tokenNumber: null });

                } else {
                    formik.setErrors({ tokenNumber: "Неверный формат жетона (АА-999999)" });
                    break;
                }

                await handlerDataLoader();
                await handleTokenNumber();
                break;
            case 1:
                await handleCallSign();
                break;
            default:
                break;
        }
        setLoading(false);
    }

    const handleClose = async (value) => {
        setOpen(false);
        if (value === undefined) return;
        setSelectedValue(value);
        setFullNameValue(`${value.fullName}`);
        await handleFillData(value.id);
        personnalDlgType ? isTokenNumber(true) : isCallSign(true);
    };

    // Валидация полей при добавлении
    const handleFieldsPersonnelIsValid = () => {
        let tokenNumber =
            (formik.values.tokenNumber !== null &&
                formik.values.tokenNumber !== undefined &&
                formik.values.tokenNumber !== '' &&
                formik.values.tokenNumber !== ' ')
                ? true
                : false;
        let surname =
            (formik.values.surname !== null &&
                formik.values.surname !== undefined &&
                formik.values.surname !== '' &&
                formik.values.surname !== ' ')
                ? true
                : false;
        let name =
            (formik.values.name !== null &&
                formik.values.name !== undefined &&
                formik.values.name !== '' &&
                formik.values.name !== ' ')
                ? true
                : false;
        return (tokenNumber && surname && name) ? true : false;
    }

    // Валидация полей при добавлении
    const handleFieldsInsertIsValid = () => {
        let locationWhereInjury =
            (formik.values.locationWhereInjury !== null &&
                formik.values.locationWhereInjury !== undefined &&
                formik.values.locationWhereInjury !== '' &&
                formik.values.locationWhereInjury !== ' ')
                ? true
                : false;
        let circumstances =
            (formik.values.circumstances !== null &&
                formik.values.circumstances !== undefined &&
                formik.values.circumstances !== '' &&
                formik.values.circumstances !== ' ')
                ? true
                : false;
        let natureOfDiagnosis =
            (formik.values.natureOfDiagnosis !== null &&
                formik.values.natureOfDiagnosis !== undefined &&
                formik.values.natureOfDiagnosis !== '' &&
                formik.values.natureOfDiagnosis !== ' ')
                ? true
                : false;
        let gravityOfInjury =
            (formik.values.gravityOfInjury !== null &&
                formik.values.gravityOfInjury !== undefined &&
                formik.values.gravityOfInjury !== '' &&
                formik.values.gravityOfInjury !== ' ')
                ? true
                : false;

        return (locationWhereInjury || natureOfDiagnosis || circumstances || gravityOfInjury);
    }

    // Валидация полей при обновлении
    const handleFieldsUpdateIsValid = () => {
        let locationMedicalAssistance =
            (formik.values.locationMedicalAssistance !== null &&
                formik.values.locationMedicalAssistance !== undefined &&
                formik.values.locationMedicalAssistance !== '' &&
                formik.values.locationMedicalAssistance !== ' ')
                ? true
                : false;
        let diagnosis =
            (formik.values.diagnosis !== null &&
                formik.values.diagnosis !== undefined &&
                formik.values.diagnosis !== '' &&
                formik.values.diagnosis !== ' ')
                ? true
                : false;
        let assistanceProvided =
            (formik.values.assistanceProvided !== null &&
                formik.values.assistanceProvided !== undefined &&
                formik.values.assistanceProvided !== '' &&
                formik.values.assistanceProvided !== ' ')
                ? true
                : false;
        let recommendations =
            (formik.values.recommendations !== null &&
                formik.values.recommendations !== undefined &&
                formik.values.recommendations !== '' &&
                formik.values.recommendations !== ' ')
                ? true
                : false;

        return (diagnosis || locationMedicalAssistance || assistanceProvided || recommendations);
    }

    // Добавление записи в журналы
    const handleRecordInsert = async () => {
        // Добавление записи в личный состав
        let idPersonnel = isVisibleFullName ? '' : selectedValue.id;
        if (isVisibleFullName) {
            // Валидация обязательных полей
            if (!handleFieldsPersonnelIsValid()) {
                isValid = false;
                alertInfo('Не все обязательные поля заполнены!');

                return;
            }

            let militaryPath = isEditMilitaryPath ? formik.values.militaryUnitEdit : formik.values.militaryUnit.label;
            let militaryUnit = isEditMilitaryUnit ? formik.values.divisionEdit : formik.values.division.label;
            let militaryCompany = isEditMilitaryCompany ? formik.values.rotaEdit : formik.values.rota.label;
            let militaryPlatoon = isEditMilitaryPlatoon ? formik.values.platoonEdit : formik.values.platoon.label;
            let militaryDepartment = isEditMilitaryDepartment ? formik.values.departmentEdit : formik.values.department.label;
            let militaryRank = isEditMilitaryRank ? formik.values.actualRankEdit : formik.values.actualRank.label;

            let personnel = {
                tokenNumber: formik.values.tokenNumber,
                callSign: formik.values.callSign,
                surname: formik.values.surname,
                name: formik.values.name,
                patronymic: formik.values.patronymic,
                birthday: new Date(formik.values.birthday).toISOString(),
                phoneNumber: formik.values.phoneNumber,
                militaryUnit: militaryPath,
                division: militaryUnit,
                rota: militaryCompany,
                platoon: militaryPlatoon,
                department: militaryDepartment,
                militaryRank: militaryRank,
            };
            let res = await RegPersonnelAsync(personnel.tokenNumber, personnel.callSign, personnel.surname, personnel.name,
                personnel.patronymic, personnel.birthday, personnel.phoneNumber, personnel.militaryUnit, personnel.division,
                personnel.rota, personnel.platoon, personnel.department, personnel.militaryRank);
            // Валидация запроса
            if (!IsCode200(res)) {
                if (IsCode503(res)) {
                    alertError('Сервис недоступен!');
                    isOnline = false;
                    setLoading(false);

                    return;
                };

                alertWarn('Не удалось создать запись!');
                setLoading(false);

                return;
            }

            idPersonnel = res.result;
        }

        // Валидация обязательных полей
        if (!handleFieldsInsertIsValid()) {
            isValid = false;
            alertInfo('Заполните поля формы в начале!');

            return;
        }

        let dateAction = formik.values.dateAction === ''
            ? null
            : new Date(formik.values.dateAction).toISOString();
        let dateOfInjury = dayjs(formik.values.dateOfInjury).isValid()
            ? formik.values.dateOfInjury
            : null;
        let dateOfEvacuation = dayjs(formik.values.dateOfEvacuation).isValid()
            ? formik.values.dateOfEvacuation
            : null;
        let locationWhereInjury = isEditLocationWhereInjury
            ? formik.values.locationWhereInjuryEdit
            : formik.values.locationWhereInjury?.label;
        let locationMedicalAssistance = isEditLocationMedicalAssistance
            ? formik.values.locationMedicalAssistanceEdit
            : formik.values.locationMedicalAssistance?.label;
        let institutionIsLocation = isEditMilitaryRank
            ? formik.values.institutionIsLocationEdit
            : formik.values.institutionIsLocation?.label;
        let patient = {
            idPersonnel: idPersonnel,
            dateOfInjury: dateOfInjury,
            locationWhereInjury: locationWhereInjury ?? null,
            circumstances: formik.values.circumstances,
            complaint: formik.values.complaint,
            natureOfDiagnosis: formik.values.natureOfDiagnosis,
            gravityOfInjury: formik.values.gravityOfInjury?.label,

            dateAction: dateAction,
            diagnosis: formik.values.diagnosis,
            helpProvided: formik.values.assistanceProvided,
            locationMedicalAssistance: locationMedicalAssistance ?? null,
            recommendations: formik.values.recommendations,
            institutionIsLocation: institutionIsLocation ?? null,
            dateOfEvacuation: dateOfEvacuation,
        };
        let res = await Reg300Async(patient.idPersonnel, patient.dateOfInjury, patient.locationWhereInjury, patient.circumstances,
            patient.complaint, patient.natureOfDiagnosis, patient.gravityOfInjury, patient.dateAction, patient.diagnosis, patient.helpProvided,
            patient.locationMedicalAssistance, patient.recommendations, patient.institutionIsLocation, patient.dateOfEvacuation);
        // Валидация запроса
        if (!IsCode200(res)) {
            if (IsCode503(res)) {
                alertError('Сервис недоступен!');
                isOnline = false;
                setLoading(false);

                return;
            };

            alertWarn('Не удалось создать запись!');
            setLoading(false);

            return;
        }

        isValid = true;
        alertSuccess('Запись успешно добавлена!');
    }

    // Обновление записи в журнале
    const handleRecordUpdate = async () => {
        // Валидация полей
        if (!handleFieldsUpdateIsValid()) {
            isValid = false;
            alertInfo('Не все обязательные поля истории болезни заполнены!');

            return;
        }

        let dateAction = formik.values.dateAction === undefined || formik.values.dateAction === null ||
            formik.values.dateAction === ''
            ? null
            : new Date(formik.values.dateAction).toISOString();
        let dateOfInjury = formik.values.dateOfInjury === undefined || formik.values.dateOfInjury === null ||
            formik.values.dateOfInjury === ''
            ? null
            : new Date(formik.values.dateOfInjury).toISOString();
        let dateOfEvacuation = formik.values.dateOfEvacuation === undefined || formik.values.dateOfEvacuation === null ||
            formik.values.dateOfEvacuation === ''
            ? null
            : new Date(formik.values.dateOfEvacuation).toISOString();
        let locationWhereInjury = isEditLocationWhereInjury
            ? formik.values.locationWhereInjuryEdit
            : formik.values.locationWhereInjury?.label;
        let locationMedicalAssistance = isEditLocationMedicalAssistance
            ? formik.values.locationMedicalAssistanceEdit
            : formik.values.locationMedicalAssistance?.label;
        let institutionIsLocation = isEditInstitutionIsLocation
            ? formik.values.institutionIsLocationEdit
            : formik.values.institutionIsLocation?.label;
        let gravityOfInjury = formik.values.gravityOfInjury?.label;
        let item = {
            idEvent: idEvent,
            idHistory: idHistory,
            idPersonnel: selectedValue.id,
            dateOfInjury: dateOfInjury,
            locationWhereInjury: locationWhereInjury ?? null,
            circumstances: formik.values.circumstances,
            complaint: formik.values.complaint,
            nature: formik.values.natureOfDiagnosis,
            gravityOfInjury: gravityOfInjury ?? null,
            dateOfVisits: dateAction,
            locationMedicalAssistance: locationMedicalAssistance ?? null,
            helpProvided: formik.values.assistanceProvided,
            diagnosis: formik.values.diagnosis,
            recommendations: formik.values.recommendations,
            locationWhere: institutionIsLocation ?? null,
            dateOfEvacuation: dateOfEvacuation,
        }
        console.log(formik.values);
        console.log(item);
        let res = await Upd300Async(item.idEvent, item.idHistory, item.idPersonnel, item.dateOfInjury, item.locationWhereInjury, item.circumstances,
            item.complaint, item.nature, item.gravityOfInjury, item.dateOfVisits, item.locationMedicalAssistance, item.helpProvided,
            item.diagnosis, item.recommendations, item.locationWhere, dateOfEvacuation);
        // Валидация запроса
        if (!IsCode200(res)) {
            if (IsCode503(res)) {
                alertError('Сервис недоступен!');
                isOnline = false;
                setLoading(false);

                return;
            };

            alertWarn('Не удалось создать запись !');
            setLoading(false);

            return;
        }

        alertSuccess('Запись успешно обновлена!');
        setIsUpdate(false);
    }

    const handleRegistration = async () => {
        console.log("");
        setLoading(true);
        isUpdate ? await handleRecordUpdate() : await handleRecordInsert();
        setLoading(false);

        console.log(isOnline);
        setTimeout(() => {
            if (isValid && isOnline) {
                navigate(urlPatientRegistration)
            }

            isOnline = true;
        }, 600);
    }

    const handleFillData = async (id) => {
        if (isVisibleFullName) {
            return;
        }

        let res = await Get300Async(id);
        // Валидация запроса
        if (!IsCode200(res)) {
            handleSetDateOfEvacuation(null);

            return;
        }
        handleSetDataAction(res.result.dateAction);
        handleSetDateOfInjury(res.result.dateOfInjury);
        handleSetDateOfEvacuation(res.result.dateOfEvacuation);

        let gravityOfInjury = res.result.gravityOfInjury ?? '-';
        let elemGravityOfInjury = gravityOfInjuryJson.find(item => item.label === gravityOfInjury);
        let locationWhereInjury = res.result.locationWhereInjury ?? '-';
        let elemLocationWhereInjury = locationWhereInjuryList.find(item => item.label === locationWhereInjury);
        let locationMedicalAssistance = res.result.locationMedicalAssistance ?? '-';
        let elemLocationMedicalAssistance = locationMedicalAssistanceList.find(item => item.label === locationMedicalAssistance);
        let institutionIsLocation = res.result.locationWhere ?? '-';
        let elemInstitutionIsLocation = institutionIsLocationList.find(item => item.label === institutionIsLocation);

        formik.setFieldValue(model.circumstances.name, res.result.circumstances ?? '');
        formik.setFieldValue(model.natureOfDiagnosis.name, res.result.nature ?? '');
        formik.setFieldValue(model.gravityOfInjury.name, elemGravityOfInjury);
        formik.setFieldValue(model.assistanceProvided.name, res.result.helpProvided ?? '');
        formik.setFieldValue(model.diagnosis.name, res.result.diagnosis ?? '');
        formik.setFieldValue(model.recommendations.name, res.result.recommendations ?? '');
        formik.setFieldValue(model.complaint.name, res.result.complaint ?? '');

        formik.setFieldValue(model.locationMedicalAssistance.autoCompleteCbx.name, elemLocationMedicalAssistance)
        formik.setFieldValue(model.locationWhereInjury.autoCompleteCbx.name, elemLocationWhereInjury);
        formik.setFieldValue(model.institutionIsLocation.autoCompleteCbx.name, elemInstitutionIsLocation);

        setGravityOfInjury(elemGravityOfInjury);
        setLocationMedicalAssistance(elemLocationMedicalAssistance);
        setLocationWhereInjury(elemLocationWhereInjury);
        setInstitutionIsLocation(elemInstitutionIsLocation);

        setIsUpdate(res.result.idHistory === "00000000-0000-0000-0000-000000000000" ? false : true);
        setIsDisabled(res.result.stateByEvacuation > 0 ? true : false);
        setIdEvent(res.result.idEvent);
        setIdHistory(res.result.idHistory);
    }

    const handleFormAction = (values) => {
    };


    const model = {
        header: {
            thema: fields.collection2,
            label: 'Форма 300',
        },
        birthday: {
            name: 'birthday',
        },
        dateAction: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateAction',
            label: 'Время поступления / обращения',
        },
        dateOfInjury: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateOfInjury',
            label: 'Время ранения',
        },
        gravityOfInjury: {
            thema: fields.collection2,
            size: 'small',
            name: 'gravityOfInjury',
            label: 'Тяжесть ранения',
            variant: 'outlined',
            elems: gravityOfInjuryJson,
        },
        locationWhereInjury: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'locationWhereInjuryEdit',
                label: 'Где получил ранение',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'locationWhereInjury',
                label: 'Где получил ранение',
                variant: 'outlined',
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        natureOfDiagnosis: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'natureOfDiagnosis',
            label: 'Характер ранения',
            rows: '4',
        },
        complaint: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'complaint',
            label: 'Жалобы',
            rows: '4',
        },
        circumstances: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'circumstances',
            label: 'Обстоятельства ранения',
            rows: '4',
        },
        diagnosis: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'diagnosis',
            label: 'Диагноз',
            rows: '4',
        },
        assistanceProvided: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'assistanceProvided',
            label: 'Оказана помощь',
            rows: '4',
        },
        recommendations: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'recommendations',
            label: 'Рекомендации',
            rows: '4',
        },
        institutionIsLocation: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'institutionIsLocationEdit',
                label: 'Куда эвакуирован',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'institutionIsLocation',
                label: 'Куда эвакуируется',
                variant: 'outlined',
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        dateOfEvacuation: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateOfEvacuation',
            label: 'Время эвакуации / выписки',
        },
        locationMedicalAssistance: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'locationMedicalAssistanceEdit',
                label: 'Место оказания медицинской помощи',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'locationMedicalAssistance',
                label: 'Место оказания медицинской помощи',
                variant: 'outlined',
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        backBtn: {
            thema: buttons.collection1,
            icon: <UndoOutlinedIcon />,
            variant: 'contained',
            handleClick: handleClick,
            value: 'Назад',
        },
        nextBtn: {
            thema: buttons.collection1,
            icon: <MonitorHeartOutlinedIcon />,
            variant: 'contained',
            handleClick: handleNextClick,
            value: 'Далее',
        },
        insertBtn: {
            thema: buttons.collection1,
            icon: <MedicalServicesOutlinedIcon />,
            variant: 'contained',
            handleClick: handleRegistration,
            value: 'Регистрация',
        },
        updateBtn: {
            thema: buttons.collection1,
            icon: <MedicalServicesOutlinedIcon />,
            variant: 'contained',
            handleClick: handleRegistration,
            value: 'Обновление',
        },
        PersonnalDlg: {
            dialogTitle: {
                title1: 'Найденно по номеру жетона:',
                title2: 'Найденно по позывному:',
            },
            thema: dialogs.collection1
        },
    }
    let context = {
        tokenNumber: '',
        callSign: '',
        surname: '',
        name: '',
        patronymic: '',
        complaint: '',
        birthday: '',
        phoneNumber: '',
        militaryUnit: { id: '', label: '' },
        militaryUnitEdit: '',
        division: { id: '', label: '' },
        divisionEdit: '',
        rota: { id: '', label: '' },
        rotaEdit: '',
        platoon: { id: '', label: '' },
        platoonEdit: '',
        department: { id: '', label: '' },
        departmentEdit: '',
        actualRank: { id: '', label: '' },
        actualRankEdit: '',
        dateAction: null,
        dateOfInjury: null,
        locationWhereInjury: { id: '', label: '' },
        locationWhereInjuryEdit: '',
        gravityOfInjury: { id: '', label: '' },
        natureOfDiagnosis: '',
        circumstances: '',
        diagnosis: '',
        locationMedicalAssistance: { id: '', label: '' },
        locationMedicalAssistanceEdit: '',
        assistanceProvided: '',
        recommendations: '',
        typeOfDirection: '',
        institutionIsLocation: { id: '', label: '' },
        institutionIsLocationEdit: '',
        dateOfEvacuation: null,
    }
    const validationSchema = Yup.object().shape({
    });


    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: validationSchema,
        initialValues: context
    });
    return (
        <Grid
            sx={{
                mt: '15px',
            }}
            container
        >
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={0} lg={1} xl={1}
            />
            <Grid
                item xs={12} sm={12} md={12} lg={10} xl={10}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Card
                        sx={{
                            mx: '5px',
                            py: '15px',
                            px: '10px',
                            borderRadius: '10px'
                        }}
                        variant="outlined"
                    >
                        <Grid
                            container spacing={2}
                        >
                            <Grid
                                style={{
                                    textAlign: 'center',
                                }}
                                item xs={12} sm={12} md={12} lg={12} xl={12}
                            >
                                <Typography
                                    variant="h4" color={model.header.thema.borderColor}
                                >
                                    {model.header.label}
                                </Typography>

                            </Grid>
                            <TokenNumber
                                formik={formik} tokenNumberOk={tokenNumberOk}
                                handleKeyUp={handleTokenNumberKeyUp} handleClick={handleTokenNumberClick}
                            />
                            {step >= 1 ?
                                isVisibleCallSign ?
                                    <CallSign
                                        formik={formik} callSignOk={callSignOk}
                                        handleKeyUp={handleCallSignKeyUp} handleClick={handleCallSignClick}
                                    /> : <></> :
                                <></>
                            }
                            {step >= 2 ?
                                isVisibleFullName ?
                                    <FullNameInput
                                        formik={formik}
                                        militaryPartList={militaryPartJson} militaryPart={militaryPart} setMilitaryPart={setMilitaryPart} handleClickMilitaryPath={handleClickMilitaryPath} isEditMilitaryPath={isEditMilitaryPath}
                                        militaryUnitList={militaryUnitJson} militaryUnit={militaryUnit} setMilitaryUnit={setMilitaryUnit} handleClickMilitaryUnit={handleClickMilitaryUnit} isEditMilitaryUnit={isEditMilitaryUnit}
                                        militaryCompanyList={militaryCompanyJson} militaryCompany={militaryCompany} setMilitaryCompany={setMilitaryCompany} handleClickMilitaryCompany={handleClickMilitaryCompany} isEditMilitaryCompany={isEditMilitaryCompany}
                                        militaryPlatoonList={militaryPlatoonJson} militaryPlatoon={militaryPlatoon} setMilitaryPlatoon={setMilitaryPlatoon} handleClickMilitaryPlatoon={handleClickMilitaryPlatoon} isEditMilitaryPlatoon={isEditMilitaryPlatoon}
                                        militaryDepartmentList={militaryDepartmentJson} militaryDepartment={militaryDepartment} setMilitaryDepartment={setMilitaryDepartment} handleClickMilitaryDepartment={handleClickMilitaryDepartment} isEditMilitaryDepartment={isEditMilitaryDepartment}
                                        militaryRankList={militaryRankJson} militaryRank={militaryRank} setMilitaryRank={setMilitaryRank} handleClickMilitaryRank={handleClickMilitaryRank} isEditMilitaryRank={isEditMilitaryRank}
                                    /> :
                                    <FullNameShow
                                        fullNameValue={fullNameValue}
                                    /> :
                                <></>
                            }
                            {step >= 2 ? <>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={3} xl={3}
                                >
                                    <DateTimeDesktopFld
                                        thema={model.dateOfInjury.thema} name={model.dateOfInjury.name} size={model.dateOfInjury.size}
                                        variant={model.dateOfInjury.variant} label={model.dateOfInjury.label}
                                        value={formik.values.dateOfInjury} isDisabled={isDisabled}
                                        handleBlur={formik.handleBlur} handleChange={handleSetDateOfInjury}
                                        touched={formik.touched.dateOfInjury} error={formik.errors.dateOfInjury}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={3} xl={3}
                                >
                                    <AutoCompleteCbx
                                        thema={model.gravityOfInjury.thema} name={model.gravityOfInjury.name} size={model.gravityOfInjury.size}
                                        elems={model.gravityOfInjury.elems} variant={model.gravityOfInjury.variant} label={model.gravityOfInjury.label}
                                        isDisabled={isDisabled}
                                        value={formik.values.gravityOfInjury} val={gravityOfInjury}
                                        handleBlur={formik.handleBlur} setFieldValue={formik.setFieldValue} handleSetState={setGravityOfInjury}
                                        touched={formik.touched.gravityOfInjury} error={formik.errors.gravityOfInjury}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={6} xl={6}
                                >
                                    <ComboBoxEdit
                                        model={model.locationWhereInjury} elems={locationWhereInjuryJson} value={formik.values.locationWhereInjury}
                                        valueEdit={formik.values.locationWhereInjuryEdit} val={locationWhereInjury} isEdit={isEditLocationWhereInjury}
                                        isDisabled={isDisabled}
                                        setFieldValue={formik.setFieldValue} handleSetState={setLocationWhereInjury} handleClick={handleClickLocationWhereInjury}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange} handleChangeEdit={formik.handleChange}
                                        touched={formik.touched.locationWhereInjury} touchedEdit={formik.touched.locationWhereInjuryEdit}
                                        error={formik.errors.locationWhereInjury} errorEdit={formik.errors.locationWhereInjuryEdit}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={6} xl={6}
                                >
                                    <MultilineFld
                                        thema={model.circumstances.thema} size={model.circumstances.size} type={model.circumstances.type}
                                        variant={model.circumstances.variant} name={model.circumstances.name} label={model.circumstances.label}
                                        rows={model.circumstances.rows} value={formik.values.circumstances} isDisabled={isDisabled}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.circumstances} error={formik.errors.circumstances}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={6} xl={6}
                                >
                                    <MultilineFld
                                        thema={model.natureOfDiagnosis.thema} size={model.natureOfDiagnosis.size} type={model.natureOfDiagnosis.type}
                                        variant={model.natureOfDiagnosis.variant} name={model.natureOfDiagnosis.name} label={model.natureOfDiagnosis.label}
                                        rows={model.natureOfDiagnosis.rows} value={formik.values.natureOfDiagnosis} isDisabled={isDisabled}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.natureOfDiagnosis} error={formik.errors.natureOfDiagnosis}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={6} xl={6}
                                >
                                    <DateTimeDesktopFld
                                        thema={model.dateAction.thema} name={model.dateAction.name} size={model.dateAction.size}
                                        variant={model.dateAction.variant} label={model.dateAction.label}
                                        value={formik.values.dateAction}
                                        handleBlur={formik.handleBlur} handleChange={handleSetDataAction}
                                        touched={formik.touched.dateAction} error={formik.errors.dateAction}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={6} xl={6}
                                >
                                    <ComboBoxEdit
                                        model={model.locationMedicalAssistance} elems={locationMedicalAssistanceJson} value={formik.values.locationMedicalAssistance}
                                        valueEdit={formik.values.locationMedicalAssistanceEdit} val={locationMedicalAssistance} isEdit={isEditLocationMedicalAssistance}
                                        setFieldValue={formik.setFieldValue} handleSetState={setLocationMedicalAssistance} handleClick={handleClickLocationMedicalAssistance}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange} handleChangeEdit={formik.handleChange}
                                        touched={formik.touched.locationMedicalAssistance} touchedEdit={formik.touched.locationMedicalAssistanceEdit}
                                        error={formik.errors.locationMedicalAssistance} errorEdit={formik.errors.locationMedicalAssistanceEdit}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={6} xl={6}
                                >
                                    <MultilineFld
                                        thema={model.complaint.thema} size={model.complaint.size} type={model.complaint.type}
                                        variant={model.complaint.variant} name={model.complaint.name} label={model.complaint.label}
                                        rows={model.complaint.rows} value={formik.values.complaint}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.complaint} error={formik.errors.complaint}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={6} xl={6}
                                >
                                    <MultilineFld
                                        thema={model.diagnosis.thema} size={model.diagnosis.size} type={model.diagnosis.type}
                                        variant={model.diagnosis.variant} name={model.diagnosis.name} label={model.diagnosis.label}
                                        rows={model.diagnosis.rows} value={formik.values.diagnosis}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange} handleClick={handleClickDiagnosis}
                                        touched={formik.touched.diagnosis} error={formik.errors.diagnosis}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={6} xl={6}
                                >
                                    <MultilineFld
                                        thema={model.assistanceProvided.thema} size={model.assistanceProvided.size} type={model.assistanceProvided.type}
                                        variant={model.assistanceProvided.variant} name={model.assistanceProvided.name} label={model.assistanceProvided.label}
                                        rows={model.assistanceProvided.rows} value={formik.values.assistanceProvided}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.assistanceProvided} error={formik.errors.assistanceProvided}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={6} xl={6}
                                >
                                    <MultilineFld
                                        thema={model.recommendations.thema} size={model.recommendations.size} type={model.recommendations.type}
                                        variant={model.recommendations.variant} name={model.recommendations.name} label={model.recommendations.label}
                                        rows={model.recommendations.rows} value={formik.values.recommendations}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.recommendations} error={formik.errors.recommendations}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={8} xl={8}
                                >
                                    <ComboBoxEdit
                                        model={model.institutionIsLocation} elems={institutionIsLocationJson} value={formik.values.institutionIsLocation}
                                        valueEdit={formik.values.institutionIsLocationEdit} val={institutionIsLocation} isEdit={isEditInstitutionIsLocation}
                                        setFieldValue={handleChangeInstitutionIsLocation} handleSetState={setInstitutionIsLocation} handleClick={handleClickInstitutionIsLocation}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange} handleChangeEdit={handleChangeInstitutionIsLocationEdit}
                                        touched={formik.touched.institutionIsLocation} touchedEdit={formik.touched.institutionIsLocationEdit}
                                        error={formik.errors.institutionIsLocation} errorEdit={formik.errors.institutionIsLocationEdit}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={4} xl={4}
                                >
                                    <DateTimeDesktopFld
                                        thema={model.dateOfEvacuation.thema} name={model.dateOfEvacuation.name} size={model.dateOfEvacuation.size}
                                        type={model.dateOfEvacuation.type} variant={model.dateOfEvacuation.variant} label={model.dateOfEvacuation.label}
                                        value={formik.values.dateOfEvacuation}
                                        handleBlur={formik.handleBlur} handleChange={handleSetDateOfEvacuation}
                                        touched={formik.touched.dateOfEvacuation} error={formik.errors.dateOfEvacuation}
                                    />
                                </Grid></> :
                                <></>
                            }
                            <Grid
                                item xs={5} sm={5} md={2} lg={3} xl={3}
                            >
                                <StartIconBtn
                                    thema={model.backBtn.thema} icon={model.backBtn.icon} variant={model.backBtn.variant}
                                    value={model.backBtn.value}
                                    handleClick={model.backBtn.handleClick}
                                />
                            </Grid>
                            <Grid
                                item xs={2} sm={2} md={8} lg={6} xl={6}
                            />
                            <Grid
                                item xs={5} sm={5} md={2} lg={3} xl={3}
                            >
                                {loading === true ?
                                    <CircularRightPrg /> :
                                    step === 2 ?
                                        isUpdate ?
                                            <EndIconBtn
                                                thema={model.updateBtn.thema} icon={model.updateBtn.icon} variant={model.updateBtn.variant}
                                                value={model.updateBtn.value}
                                                handleClick={model.updateBtn.handleClick}
                                            /> :
                                            <EndIconBtn
                                                thema={model.insertBtn.thema} icon={model.insertBtn.icon} variant={model.insertBtn.variant}
                                                value={model.insertBtn.value}
                                                handleClick={model.insertBtn.handleClick}
                                            /> :
                                        <EndIconBtn
                                            thema={model.nextBtn.thema} icon={model.nextBtn.icon} variant={model.nextBtn.variant}
                                            value={model.nextBtn.value}
                                            handleClick={model.nextBtn.handleClick}
                                        />
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </form>
            </Grid>
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={0} lg={1} xl={1}
            />
            <PersonnalDlg
                thema={model.PersonnalDlg.thema} dialogTitle={model.PersonnalDlg.dialogTitle} elems={personnels}
                selectedValue={selectedValue} open={open} onClose={handleClose} type={personnalDlgType}
            />
        </Grid>
    )
}
