import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router';

import { AlertToast } from '../components/Alerts';
import { UserCheck } from '../common/helper/Cheking';

import AppBarMenu from '../widgets/AppBarMenu';
import LeftMenu from '../widgets/LeftMenu';
import SelectorDocuments from '../widgets/SelectorDocuments';
import PTransferableEpicrisisNew from './Documents/PTransferableEpicrisis';
import PTransferableEpicrisis from './Documents/PTransferableEpicrisis';
import DocByMilitaryDischarge from '../widgets/DocByMilitaryDischarge';
import DocByOutpatientCard from '../widgets/DocByPatientCard';


/**
 * Страница журнал приема
 * @returns страница
 */
export default function PDocuments(props) {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuExportOpen, setMenuExportOpen] = useState(false);
    const [role, setRole] = useState('');

    useEffect(() => {
        UserCheck(navigate);
        let buf = JSON.parse(sessionStorage.getItem('authentication'));
        setRole(buf.role);
    }, []);

    const toggleDrawerOpen = () => {
        setMenuOpen(true);
    };
    const toggleDrawerClose = () => {
        setMenuOpen(false);
    };

    const handleMenuExport = () => {
        setMenuExportOpen(!menuExportOpen);
    }

    const model = {
        appBarMenu: {
            headerValue: 'Документы',
        },
    } 

    return (
        <>
            <AppBarMenu 
                headerValue={model.appBarMenu.headerValue} 
                handleClick={toggleDrawerOpen}
            />
            <LeftMenu 
                open={menuOpen} openMenuExport={menuExportOpen} role={role}
                handleClick={toggleDrawerClose} handleMenuExport={handleMenuExport}
            />
            {(() => {
                switch(props.step){
                    case 1:
                        return <PTransferableEpicrisis />
                    case 2:
                        return <></>
                    case 3:
                        return <DocByOutpatientCard />            
                    default:
                        return <SelectorDocuments />
                }
            })()}
            <AlertToast />
        </>
    )
}
