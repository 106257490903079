import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


/**
 * Компонент контейнер уведомлений
 * @param { Object } Передаваемые параметры в компонент
 */
function AlertToast(props) {
    return (
        <ToastContainer
            style={{ 
                width: "400px" 
            }}
            position='bottom-center' autoClose={1000} hideProgressBar={false} newestOnTop={false}
            closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light' transition={Flip} 
            draggablePercent={45}
        />
    )
}

export {
    AlertToast
}