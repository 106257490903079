import React, { memo, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Card,
    Grid,
    Typography,
} from "@mui/material";
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';

import PersonIcon from '@mui/icons-material/Person';

import {
    selectCurrentPatientId,
} from "../history/historySlice";

import { fields, buttons, dialogs } from '../../app/thema';

import { Search } from "./components/Search";
import { Patient } from "./components/Patient";


const HistoryComponent = () => {
    const dispatch = useDispatch();

    let currentPatientId = useSelector(selectCurrentPatientId);

    const [loading, setLoading] = useState(false);


    return <Grid
        className="no-print"
        sx={{
            mt: '15px',
        }}
        container
    >
        <Grid
            item
            // xs={1}
            md={0}
            lg={1}
        />
        <Grid
            item
            md={12}
            lg={10}
        >
            <form>
                <Card
                    sx={{
                        mx: '5px',
                        py: '15px',
                        px: '10px',
                        borderRadius: '10px'
                    }}
                    variant="outlined"
                >
                    <Grid
                        container spacing={2}
                    >
                        <Grid
                            style={{
                                textAlign: 'center',
                            }}
                            item
                            xs={12}
                        >
                            <Typography
                                variant="h4"
                                color={fields.collection2.borderColor}
                            >
                                Этапы эвакуации
                            </Typography>

                        </Grid>




                        <Box sx={{ width: '100%', pl: 3, pr: 3 }}>
                            <Search
                                variant="parse"
                                type="TokenFIO"
                                label={"Личный номер или ФИО"}
                                itemIcon={<PersonIcon sx={{ color: 'text.secondary' }} />}
                                inputColor="#344C1129"
                                sx={{
                                    backgroundColor: "#fcfdfd",
                                }}
                                resultItemProps={{
                                    sx: {
                                        display: "inline-block",
                                        "&:first-letter": {
                                            textTransform: "uppercase",
                                        }
                                    }
                                }}

                            />

                            {/* <DiseaseList /> */}


                            {currentPatientId ? <Patient /> : null}
                        </Box>












                        {/* <TokenNumber
                        formik={formik} tokenNumberOk={tokenNumberOk}
                        handleKeyUp={handleTokenNumberKeyUp} handleClick={handleTokenNumberClick}
                    /> */}

                        {/* <Grid
                            item
                            xs={5}
                            sm={5}
                            md={2}
                            lg={3}
                            xl={3}
                        >
                            <StartIconBtn
                                thema={buttons.collection1}
                                icon={<UndoOutlinedIcon />}
                                variant='contained'
                                value={'Назад'}
                            // handleClick={model.backBtn.handleClick}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sm={2}
                            md={8}
                            lg={6}
                            xl={6}
                        />
                        <Grid
                            item
                            xs={5}
                            sm={5}
                            md={2}
                            lg={3}
                            xl={3}
                        >
                            {loading === true ?
                                "loading" :
                                <EndIconBtn
                                    thema={buttons.collection1}
                                    icon={<MonitorHeartOutlinedIcon />}
                                    variant='contained'
                                    value={'Далее'}
                                // handleClick={model.nextBtn.handleClick}
                                />
                            }
                        </Grid> */}
                    </Grid>
                </Card>
            </form>
        </Grid>
        <Grid
            item
            md={0}
            lg={1}
        />

    </Grid>;
}



export const History = memo(HistoryComponent);
