import React from 'react';
import { fields, buttons, dialogs } from '../app/thema';

import { Grid, Card, Typography } from '@mui/material';

import { ComboBoxEdit } from '../widgets/ComboBoxEdit';


/**
 * Виджет форма ввода данных о военном билете
 * @param { Object } props передаваемые параметры
 * @returns виджет
 */
export default function InfoByMilitaryTicketFull(props) {
    const model = { 
        militaryPart: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryPartEdit',
                label: 'Воинская часть',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryPart',
                label: 'Воинская часть',
                variant: 'outlined',
                elems: props.militaryPartList,
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        militaryUnit: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryUnitEdit',
                label: 'Подразделение',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryUnit',
                label: 'Подразделение',
                variant: 'outlined',
                elems: props.militaryUnitList,
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        militaryCompany: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryCompanyEdit',
                label: 'Рота',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryCompany',
                label: 'Рота',
                variant: 'outlined',
                elems: props.militaryCompanyList,
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        militaryPlatoon: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryPlatoonEdit',
                label: 'Взвод',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryPlatoon',
                label: 'Взвод',
                variant: 'outlined',
                elems: props.militaryPlatoonList,
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        militaryDepartment: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryDepartmentEdit',
                label: 'Отделение',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryDepartment',
                label: 'Отделение',
                variant: 'outlined',
                elems: props.militaryDepartmentList,
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        militaryRank: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryRankEdit',
                label: 'Воинское звание',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryRank',
                label: 'Воинское звание',
                variant: 'outlined',
                elems: props.militaryRankList,
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
    };    
    return (
        <>
            <Grid
                item xs={12} sm={12} md={6} lg={4} xl={4}
            >
                <ComboBoxEdit
                    model={model.militaryPart} elems={model.militaryPart.autoCompleteCbx.elems}
                    value={props.formik.values.militaryUnit} valueEdit={props.formik.values.militaryPartEdit} 
                    val={props.militaryPart} 
                    isEdit={props.isEditMilitaryPath} isDisabled={props.isDisabledMilitaryPath}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setMilitaryPart}
                    handleClick={props.handleClickMilitaryPath} handleBlur={props.formik.handleBlur} 
                    handleChange={props.formik.handleChange}
                    touched={props.formik.touched.militaryPart} touchedEdit={props.formik.touched.militaryPartEdit}
                    error={props.formik.errors.militaryPart} errorEdit={props.formik.errors.militaryPartEdit}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={6} lg={4} xl={4}
            >
                <ComboBoxEdit
                    model={model.militaryUnit} elems={model.militaryUnit.autoCompleteCbx.elems} 
                    value={props.formik.values.militaryUnit} valueEdit={props.formik.values.militaryUnitEdit}
                    val={props.militaryUnit} 
                    isEdit={props.isEditMilitaryUnit} isDisabled={props.isDisabledMilitaryUnit}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setMilitaryUnit} 
                    handleClick={props.handleClickMilitaryUnit} handleBlur={props.formik.handleBlur} 
                    handleChange={props.formik.handleChange}
                    touched={props.formik.touched.militaryUnit} touchedEdit={props.formik.touched.militaryUnitEdit}
                    error={props.formik.errors.militaryUnit} errorEdit={props.formik.errors.militaryUnitEdit}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={6} lg={4} xl={4}
            >
                <ComboBoxEdit
                    model={model.militaryCompany} elems={model.militaryCompany.autoCompleteCbx.elems} 
                    value={props.formik.values.militaryCompany} valueEdit={props.formik.values.militaryCompanyEdit} 
                    val={props.militaryCompany} 
                    isEdit={props.isEditMilitaryCompany} isDisabled={props.isDisabledMilitaryCompany}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setMilitaryCompany} 
                    handleClick={props.handleClickMilitaryCompany} handleBlur={props.formik.handleBlur} 
                    handleChange={props.formik.handleChange}
                    touched={props.formik.touched.militaryCompany} touchedEdit={props.formik.touched.militaryCompanyEdit}
                    error={props.formik.errors.militaryCompany} errorEdit={props.formik.errors.militaryCompanyEdit}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={6} lg={4} xl={4}
            >
                <ComboBoxEdit
                    model={model.militaryPlatoon} elems={model.militaryPlatoon.autoCompleteCbx.elems} 
                    value={props.formik.values.militaryPlatoon} valueEdit={props.formik.values.militaryPlatoonEdit} 
                    val={props.militaryPlatoon} 
                    isEdit={props.isEditMilitaryPlatoon} isDisabled={props.isDisabledMilitaryPlatoon}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setMilitaryPlatoon}
                    handleClick={props.handleClickMilitaryPlatoon} handleBlur={props.formik.handleBlur}
                    handleChange={props.formik.handleChange}
                    touched={props.formik.touched.militaryPlatoon} touchedEdit={props.formik.touched.militaryPlatoonEdit}
                    error={props.formik.errors.militaryPlatoon} errorEdit={props.formik.errors.militaryPlatoonEdit}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={6} lg={4} xl={4}
            >
                <ComboBoxEdit
                    model={model.militaryDepartment} elems={model.militaryDepartment.autoCompleteCbx.elems} 
                    value={props.formik.values.militaryDepartment} valueEdit={props.formik.values.militaryDepartmentEdit} 
                    val={props.militaryDepartment} 
                    isEdit={props.isEditMilitaryDepartment} isDisabled={props.isDisabledMilitaryDepartment}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setMilitaryDepartment} 
                    handleClick={props.handleClickMilitaryDepartment} handleBlur={props.formik.handleBlur} 
                    handleChange={props.formik.handleChange}
                    touched={props.formik.touched.militaryDepartment} touchedEdit={props.formik.touched.militaryDepartmentEdit}
                    error={props.formik.errors.militaryDepartment} errorEdit={props.formik.errors.militaryDepartmentEdit}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={6} lg={4} xl={4}
            >
                <ComboBoxEdit
                    model={model.militaryRank} elems={model.militaryRank.autoCompleteCbx.elems} value={props.formik.values.militaryRank}
                    valueEdit={props.formik.values.militaryRankEdit} val={props.militaryRank} 
                    isEdit={props.isEditMilitaryRank} isDisabled={props.isDisabledMilitaryRank}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setMilitaryRank} handleClick={props.handleClickMilitaryRank}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.militaryRank} touchedEdit={props.formik.touched.militaryRankEdit}
                    error={props.formik.errors.militaryRank} errorEdit={props.formik.errors.militaryRankEdit}
                />
            </Grid>
        </>
    )
}
