import React, { memo, useState, useMemo, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";
import { useNavigate, useParams } from 'react-router';

import { styled } from '@mui/material/styles';

import {
    Autocomplete,
    Divider,
    Box,
    Typography,
    Grid,
    Paper,
    Stack,
    TextField,
} from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import {
    selectPatientData,
    setCurrentHistoryItem
} from "../../historySlice";

import { OtherDiseases } from "../OtherDiseases";
import { HistoryItem } from "../HistoryItem";


const Item = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    margin: "0 !important",
    // padding: theme.spacing(1),
    textAlign: 'right',
    color: theme.palette.text.secondary,
}));

// const SEARCHTEST = "рвота, тошнота, боль в животе, боль в левом подреберье, гипертрофия миокарда правого желудочка (ГПЖ), стеноз легочного ствола подклапанный,  дефект межжелудочковой перегородки";

const PatientComponent = () => {
    const dispatch = useDispatch();
    const params = useParams();
    console.log(params);

    // const [value, setValue] = useState(currentValue || null);

    const patientData = useSelector(selectPatientData);
    const [currentEvent, setCurrentEvent] = useState(0);
    const [isLast, setIsLast] = useState(false);
    const [openedHistoryItem, setOpenedHistoryItem] = useState({});


    const handleChangeCurrentEvent = (index) => {
        setCurrentEvent(index);
    }

    useEffect(() => {

    }, []);

    // patientInfo: {
    //     id: "123323",
    //     tokenNumber: "226749",
    //     dataBirth: "12.04.1988",
    //     fio: "Петров Василий Иванович",
    //     dateOfInjury: "20.06.2024",
    //     locationWhereInjury: "г. Белград, село Новые Старики",
    //     nature: "осколочное ранение",
    //     division: "Подразделение",
    //     militaryRank: "Рядовой",
    //     doljnost: "Должность",
    //   },


    const {
        id,
        suid,
        fio,
        birthday,
        militaryUnitCode,
        militaryUnit,
        militaryFunction,
        militaryRank,
        events
    } = patientData;

    const currentEventData = events && events[currentEvent] ? events[currentEvent] : {};
    const evacuations = currentEventData.history ? [...currentEventData.history] : [];

    let unknownLocationIndex = null;
    for (let i = 0; i < evacuations.length; i++) {
        if (evacuations[i + 1]) {
            // Приехал не туда, куда его отправили, значит по пути он был где-то ещё. Добавляем точку.
            if (evacuations[i].evacuationLocation !== evacuations[i + 1].location) {
                unknownLocationIndex = i + 1;
            }

        }

    }


    if (unknownLocationIndex) {

        let unknownLocation = {
            dischargeDate: null,
            disease: "",
            evacuationLocation: "",
            helping: "",
            hospitalizationDate: "",
            id: "UNKNOWN_" + unknownLocationIndex,
            location: "промежуточные этапы",
            status: "",
            color: "#ececec",
            type: "UNKNOWN"
        };

        evacuations.splice(unknownLocationIndex, 0, unknownLocation)
    }

    // evacuations.reverse();


    let patientLastRowInfo = [];

    if (militaryUnit) {
        patientLastRowInfo.push(militaryUnit);
    }
    if (militaryFunction) {
        patientLastRowInfo.push(militaryFunction);
    }
    if (militaryRank) {
        patientLastRowInfo.push(militaryRank);
    }



    const handleEventClick = (evacuation, index) => {
        if (evacuation.type === "UNKNOWN") {
            return;
        }
        console.log(evacuation);
        console.log(index);

        setIsLast(index === 0);

        dispatch(setCurrentHistoryItem(evacuation));
    };

    console.log(!!openedHistoryItem.id);

    console.log(evacuations);
    console.log(evacuations.evacuationLocation);

    const lastEvacuation = evacuations[0] && evacuations[0].evacuationLocation ? evacuations[0] : {};

    console.log(lastEvacuation);

    let notEndedEvacuation = {};


    if (lastEvacuation.evacuationLocation) {
        if (lastEvacuation.evacuationLocation.toLowerCase() !== "в строй") {
            notEndedEvacuation = {
                dischargeDate: null,
                disease: "",
                evacuationLocation: "",
                helping: "",
                hospitalizationDate: "",
                id: lastEvacuation.id + "NOT_ENDED",
                location: lastEvacuation.evacuationLocation,
                status: "в пути",
                color: "#cc2121",
            };
        } else {
            notEndedEvacuation = {
                dischargeDate: null,
                disease: "",
                evacuationLocation: "",
                helping: "",
                hospitalizationDate: "",
                id: lastEvacuation.id + "NOT_ENDED",
                location: lastEvacuation.evacuationLocation,
                status: "",
                color: "green",
            };
        }
    }

    return <>
        <Grid
            container
            spacing={2}
            sx={{ m: 2, mt: 4 }}
        >
            {/* <Grid
                xs={6}
                sx={{
                    borderRight: "2px solid gray",
                    p: 2,
                    flexBasis: "calc(50% - 23px) !important",
                }}
            >
                <Stack spacing={2}>
                    <Item>Дата ранения: {currentEventData.eventDate ? new Date(currentEventData.eventDate).toLocaleString() : "-"}</Item>
                    <Item>{currentEventData.location}</Item>
                    <Item></Item>
                </Stack>
            </Grid> */}
            <Grid
                item
                xs={12}
                sx={{
                    pl: 2
                }}
            >
                <Stack spacing={2} sx={{ textAlign: "center" }} >
                    <Typography variant="h6" sx={{ margin: "0 !important" }}>Личный номер №{suid}</Typography>
                    <Typography variant="h5" sx={{ margin: "0 !important" }}>{fio} {birthday ? new Date(birthday).toLocaleDateString() : ""}</Typography>
                    <Typography variant="body2" sx={{ margin: "0 !important" }}>{patientLastRowInfo.join(", ")}</Typography>
                </Stack>
            </Grid>
        </Grid>
        <Divider />

        {/* {
              dateOfVisits: "23.06.2024",
              diagnosis: "Осколочное ранение правой ноги",
              locationWhere: "Госпиталь #1"
            }, */}
        <><Timeline>


            {notEndedEvacuation.location ? <TimelineItem>
                <TimelineOppositeContent sx={{ m: '3px 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary">
                    {/* {evacuation.hospitalizationDate} */}{notEndedEvacuation.status}
                    {/* {evacuation.hospitalizationDate ? new Date(evacuation.hospitalizationDate).toLocaleString() : "-"} */}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot
                        sx={{
                            // height: 100,
                            backgroundColor: notEndedEvacuation.color,
                            opacity: 0.8,
                        }} />
                    <TimelineConnector
                        sx={{
                            height: 100,
                            opacity: 0.8,
                            background: notEndedEvacuation.color === "#cc2121" ? `linear-gradient(to bottom, ${notEndedEvacuation.color} 50%, #FFF 50% )` : "inherit",
                            backgroundSize: "100% 10px",
                            backgroundColor: notEndedEvacuation.color,
                            "&::after": {
                                content: '""',
                                width: 0,
                                height: 0,
                                borderLeft: "6px solid transparent",
                                borderRight: "6px solid transparent",
                                borderBottom: `13px solid ${notEndedEvacuation.color}`,
                                margin: "-2px 0px 0px -5px",
                                padding: "6px 0px -14px 0",
                                position: "absolute",
                            }
                        }} />
                </TimelineSeparator>
                <TimelineContent sx={{ mt: '-5px' }}>
                    <Typography variant="h6" component="span">
                        {notEndedEvacuation.location}
                    </Typography>
                    {/* <Typography> {evacuation.disease}</Typography> */}
                </TimelineContent>
            </TimelineItem> : null}



            {evacuations.map((evacuation, index) => {

                // Не очень хорошая практика, но это самое удобное место на данный момент.
                if (params.patientId && index === 0) {
                    // dispatch(setCurrentHistoryItem(evacuation));
                }

                return <Fragment key={index}>

                    <TimelineItem
                        onClick={() => handleEventClick(evacuation, index)}
                        sx={{
                            cursor: evacuation.type === "UNKNOWN" ? "default" : "pointer"
                        }}
                    >
                        <TimelineOppositeContent sx={{ m: '3px 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary">
                            {/* {evacuation.hospitalizationDate} */}
                            {evacuation.hospitalizationDate ? new Date(evacuation.hospitalizationDate).toLocaleString() : ""}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot
                                sx={{
                                    // height: 100,
                                    backgroundColor: evacuation.color || "",
                                    border: "2px solid #bdbdbd",
                                    opacity: 0.8,
                                }} />
                            <TimelineConnector
                                sx={{
                                    height: 100,
                                    "&::after": {
                                        content: '""',
                                        width: 0,
                                        height: 0,
                                        borderLeft: "6px solid transparent",
                                        borderRight: "6px solid transparent",
                                        borderBottom: "13px solid #bdbdbd",
                                        margin: "-2px 0px 0px -5px",
                                        padding: "6px 0px -14px 0",
                                        position: "absolute",
                                    }
                                }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ mt: '-5px' }}>
                            <Typography
                                sx={{
                                    opacity: !(evacuation.location && evacuation.location !== "-") || evacuation.type === "UNKNOWN" ? 0.5 : 1,
                                }}
                                variant="h6"
                                component="span"
                            >
                                {evacuation.location && evacuation.location !== "-" ? evacuation.location : "в процессе заполнения"}
                            </Typography>
                            <Typography> {evacuation.disease}</Typography>
                        </TimelineContent>
                    </TimelineItem>

                </Fragment>
            }
            )}

            {currentEventData.eventDate || currentEventData.location ? <TimelineItem
                // onClick={() => handleEventClick(evacuation)}
                sx={{
                    // cursor: "pointer"
                }}
            >
                <TimelineOppositeContent sx={{ m: '3px 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary">
                    {/* {evacuation.hospitalizationDate} */}
                    Дата ранения: {currentEventData.eventDate ? new Date(currentEventData.eventDate).toLocaleString() : "-"}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    {/* <TimelineConnector sx={{ height: 100 }} /> */}
                </TimelineSeparator>
                <TimelineContent sx={{ mt: '-5px' }}>
                    <Typography variant="h6" component="span">
                        {currentEventData.location}
                    </Typography>
                    <Typography><b>Обстоятельства:</b> {currentEventData.circumstances || " - "}</Typography>
                    <Typography><b>Характер:</b> {currentEventData.nature || " - "}</Typography>
                    <Typography><b>Степень:</b> {currentEventData.gravityOfInjury || " - "}</Typography>
                </TimelineContent>
            </TimelineItem> : null
            }

        </Timeline>
        </>

        <OtherDiseases
            currentEvent={currentEvent}
            onChangeCurrentEvent={handleChangeCurrentEvent}
        />
        <HistoryItem
            currentEvent={currentEvent}
            notEnded={!!notEndedEvacuation.location}
            isLast={isLast}
        />
    </>;
}

export const Patient = memo(PatientComponent);
