import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router';

import { AlertToast } from '../components/Alerts';
import { UserCheck } from '../common/helper/Cheking';

import AppBarMenu from '../widgets/AppBarMenu';
import LeftMenu from '../widgets/LeftMenu';
import {Recent} from '../features/recent';



/**
 * 
 * @returns 
 */
export default function PRecent(props) {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuExportOpen, setMenuExportOpen] = useState(false);
    const [role, setRole] = useState('');

    useEffect(() => {
        UserCheck(navigate);
        let buf = JSON.parse(sessionStorage.getItem('authentication'));
        setRole(buf.role);
    }, []);

    const toggleDrawerOpen = () => {
        setMenuOpen(true);
    };
    const toggleDrawerClose = () => {
        setMenuOpen(false);
    };
    const handleMenuExport = () => {
        setMenuExportOpen(!menuExportOpen);
        console.log(menuExportOpen);
    }
    const model = {
        appBarMenu: {
            headerValue: 'Мои пациенты',
        },
    } 

    return (
        <>
            <AppBarMenu 
                headerValue={model.appBarMenu.headerValue} handleClick={toggleDrawerOpen}
            />
            <LeftMenu 
                open={menuOpen} openMenuExport={menuExportOpen} role={role}
                handleClick={toggleDrawerClose} handleMenuExport={handleMenuExport}
            />
            <Recent />
            <AlertToast />
        </>
    )
}
