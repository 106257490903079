import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchSendGetHistoryPatientsByUser,
} from "./recentAPI";

// import { appStorage } from "../../helpers";




const initialState = {
  currentPatientId: null,
  currentHistoryItem: {},

  historyPatients: [],

  loading: {
    historyPatients: false,
    applicationInfo: false,
    symptoms: false,
    diagnostic: false,
    serviceInfo: false,
    serviceList: false,
    clarifications: false,
  }
};


export const sendGetHistoryPatientsByUser = createAsyncThunk(
  "history/sendGetHistoryPatientsByUser",
  async (props = {}, { getState }) => {
    const {
      id,
      //  success = () => { }
    } = props;
    // const { auth, history } = getState();

    const response = await fetchSendGetHistoryPatientsByUser({
      data: {
        id
        // authKey: auth.authKey,
        // guid: history.resultData?.guid || null
      },
      // success
    });

    return response;
  }
);



export const recentSlice = createSlice({
  name: "history",
  initialState,
  reducers: {

    addToSymptomsList: (state, action) => {
      state.symptomsList = [
        ...state.symptomsList,
        action.payload
      ];
    },


    setSetting: (state, action) => {
      const { name, value } = action.payload;
      state.settings[name] = value;

      // appStorage.setItem("settings", state.settings);
    },

    setSettings: (state, action) => {
      state.settings = action.payload;
    },

    setPatient: (state, action) => {
      state.patient = action.payload;
    },


  },

  extraReducers: (builder) => {
    builder


      .addCase(sendGetHistoryPatientsByUser.pending, (state) => {
        state.status = "loading";
        state.loading.historyPatients = true;
      })

      .addCase(sendGetHistoryPatientsByUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.historyPatients = false;

        let responseData = action.payload?.items || [];

        console.log(responseData);


        state.historyPatients = responseData;
      })

      .addCase(sendGetHistoryPatientsByUser.rejected, (state, action) => {
        state.status = "idle";
        state.loading.historyPatients = false;
      })



      ;
  },
});

export const {
  addToSymptomsList,
  addToClarificationsList,
  addToUnitsList,
  addToUnitsListFromSearch,
  addToPatientDisease,
  addToPatientEditLifesAnamnez,
  addToPatientEditDisease,
  addToPatientClarificationsList,
  addToPatientEditClarificationsList,
  addToPatientEditLifesAnamnezClarificationsList,
  removeSymptomById,
  removeClarificationById,
  removePatientDiseaseById,
  removePatientEditDiseaseById,
  removePatientEditLifesAnamnezById,
  removePatientClarificationById,
  removePatientEditLifesAnamnezClarificationById,
  removePatientEditClarificationById,
  resetSymptomsList,
  resetSettings,
  resetPatient,
  resetPatientEdit,
  setCurrentSymptom,
  setCurrentPatientDisease,
  setCurrentPatientLifesAnamnez,
  setResultGraphId,
  setRASPage,
  setSensorValue,
  setSetting,
  setSettings,
  setPatient,
  setPatientEdit,
  setCurrentHistoryItem,
  resetCurrentSymptom,
  resetCurrentPatientDisease,
  resetCurrentPatientLifesAnamnez,
} = recentSlice.actions;


export const selectHistoryPatients = (state) => state.recent.historyPatients;
export const selectLoading = (state) => state.recent.loading;
export const selectSettings = (state) => state.recent.settings;

export default recentSlice.reducer;
