import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router';

import { AlertToast } from '../components/Alerts';
import { UserCheck } from '../common/helper/Cheking';

import AppBarMenu from '../widgets/AppBarMenu';
import LeftMenu from '../widgets/LeftMenu';
import SelectorForms from '../widgets/SelectorForms';
import FormaByPatient200 from '../widgets/FormaByPatient200';
import FormaByPatient300 from '../widgets/FormaByPatient300';
import FormaByPatientDiseases from '../widgets/FormaByPatientDiseases';
import FormaByPatientTrauma from '../widgets/FormaByPatientTrauma';

/**
 * Страница по умолчанию
 * @returns страница
 */
export default function PRegistration(props) {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuExportOpen, setMenuExportOpen] = useState(false);
    const [role, setRole] = useState('');

    useEffect(() => {
        UserCheck(navigate);
        let buf = JSON.parse(sessionStorage.getItem('authentication')) || {};
        setRole(buf.role);
    }, []);

    const toggleDrawerOpen = () => {
        setMenuOpen(true);
    };
    const toggleDrawerClose = () => {
        setMenuOpen(false);
    };
    const handleMenuExport = () => {
        setMenuExportOpen(!menuExportOpen);
    }
    
    const model = {
        appBarMenu: {
            headerValue: 'Регистрация пациента',
        },
    }   

    return (
        <>
            <AppBarMenu 
                headerValue={model.appBarMenu.headerValue} handleClick={toggleDrawerOpen}
            />
            <LeftMenu 
                open={menuOpen} openMenuExport={menuExportOpen} role={role}
                handleClick={toggleDrawerClose} handleMenuExport={handleMenuExport}
            />
            {(() => {
                switch(props.step){
                    case 10:
                        return <FormaByPatientDiseases />
                    case 20:
                        return <FormaByPatientTrauma /> 
                    case 200:
                        return <FormaByPatient200 />              
                    case 300:
                        return <FormaByPatient300 />                  
                    default:
                        return <SelectorForms />
                }
            })()}
            <AlertToast />
        </>
    )
}