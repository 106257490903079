import React from 'react';
import { fields } from '../app/thema';
import { Grid } from '@mui/material';
import { TextFld } from '../components/Fields';


/**
 * 
 * @param {*} props 
 * @returns 
 */
function FullNameShow(props) {
    const model = {
        fullName: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'fullName',
            label: 'Фамилия Имя Отчество',
            isDisabled: true,
        },
    }

    return (
        <Grid
            item xs={12} sm={12} md={12} lg={12} xl={12}
        >
            <TextFld
                thema={model.fullName.thema} name={model.fullName.name} size={model.fullName.size}
                type={model.fullName.type} variant={model.fullName.variant} label={model.fullName.label}
                value={props.fullNameValue} isDisabled={model.fullName.isDisabled}
            />
        </Grid>
    )
}


export {
    FullNameShow
}