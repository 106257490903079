import { configureStore } from '@reduxjs/toolkit';

import cardindexPersonnelSlice from './reducers/cardindexPersonnelSlice';
import historyReducer from '../features/history/historySlice';
import recentReducer from '../features/recent/recentSlice';


export const store = configureStore({
    reducer: {
        cardindexPersonnel: cardindexPersonnelSlice,
        history: historyReducer,
        recent: recentReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});