import React, { useState } from 'react';

import { Grid, Card, Typography } from '@mui/material';
import { fields } from '../app/thema';

import { FindFld } from '../components/Fields';


/**
 * Виджет поле поиска
 * @param { Object } props передаваемые параметры
 * @returns виджет
 */
export default function FindByParser(props) {
    const model = {
        findFld: {
            thema: fields.collection2,
            size: 'small',
            variant: 'outlined',
            name: 'findFld',
            label: 'Поисковая строка',
        },
    }
    
    return (
        <Grid
            item xs={12} sm={12} md={12} lg={12} xl={12}
        >
            <FindFld
                thema={model.findFld.thema} name={model.findFld.name} size={model.findFld.size}
                variant={model.findFld.variant} label={model.findFld.label} value={props.formik.values.findFld} show={props.show}
                handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange} handleKeyUp={props.handleKeyUp} handleClick={props.handleClick}
                touched={props.formik.touched.findFld} error={props.formik.errors.findFld}
            />
        </Grid>
    )
}
